////
/// @group style.scss
////


// Config
// ================================
	@import 'config';


// Фикс mqpacker
// ================================
	@include mqPacker;


// Normalize
// ================================

	@include normalize__document;
	@include normalize__sections;
	@include normalize__grouping-content;
	@include normalize__text-level-semantics;
	@include normalize__embedded-content;
	@include normalize__forms;
	@include normalize__interactive;
	@include normalize__scripting;
	@include normalize__hidden;

	@include normalize__text-level-semantics__psevdo-classes;
	@include normalize__forms__psevdo-classes;
	@include normalize__addons;


// Import Partials
// ================================
	// основа
	@import '_partials/base';
	@import '_partials/_fonts';

	// структура
	@import '_partials/view-wrapper';
	@import '_partials/view-size';
	@import '_partials/view-section';
	@import '_partials/view-header';
	@import '_partials/view-footer';

	// hidden
	@import '_partials/mfp-zoom-in';
	@import '_partials/hidden-wrapper';

	// контент
	@import '_partials/view-text';
	@import '_partials/table-wrapper';
	@import '_partials/content-image';
	@import '_partials/title';

	// ui
	@import '_partials/breadcrumbs';
	@import '_partials/sitemap';
	@import '_partials/_text';
	@import '_partials/_more-link';
	@import '_partials/_buttons';
	@import '_partials/error';
	@import '_partials/buttons-default';
	@import '_partials/pagination.scss';
	@import '_partials/form';
	@import '_partials/_search';
	@import "_partials/_animations";



// Grid
// ================================
	// @include grid-full;


// Include Utils
// ================================
	@include util-colors;
	@include util-bgcolors;
	@include util-fills;

	@include util-floats;
	@include util-floats-mq;

	@include util-texts;
	@include util-texts-mq;

	@include util-visibility;
	@include util-visibility-mq;

	// @include util-gutters;
	// @include util-gutters-mq;


// Custom css
// ================================







// seoflex
.view-wrapper{
	display: flex;
	flex-direction: column;
}
.view-header{
	order: 1;
}
.view-section{
	order: 2;
}
#seo_text{
	order: 3;
	position: relative;
}
.view-footer{
	order: 4;
}








a{
	transition: color 0.5s ease;
}
.after-header{
	padding: 36px 0 18px 0;
	overflow: hidden;
	background-repeat: no-repeat;
	background-size: cover;
    background-position: 34%;
	position: relative;
	margin-bottom: rem(46);
	@include media($lg){
		background-position: 0 0;
	}

	.sub-nav__info-block{
		margin-bottom: rem(36);
	}
	&--feedfix{
		margin-bottom: 0;
	}
	&:after{
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		content: "";
		background: linear-gradient(65deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5), transparent, transparent);
	}
	&:before {
	    position: absolute;
	    bottom: 0;
	    right: 0;
	    width: 0;
	    height: 0;
	    border-bottom: 270px solid #b89b5e;
	    border-left: 270px solid transparent;
	    opacity: 0.6;
	    content: "";
	    z-index: 1;
	    display: none;
	    @include media($md){
			display: block;
		}
	}
	&__top{
		position: relative;
		z-index: 2;
	}
}
.feedback-page{
	position: relative;
}
.page-group-quostil{
	position: absolute;
	left: 0;
	right: 0;
	margin: 0 auto;
	top: rem(36);
}
.feedbacks-page{
	margin-bottom: rem(64);
}
.feedbacks-grid{
	margin-bottom: rem(30);
}
.feedback{
	padding: rem(170) 0 rem(40) 0;
	min-height: 450px;
	
	background-repeat: no-repeat;
	background-size: 76%;
	background-position: 50% 0;
	width: 100%;
	&__content{
		padding: 0 rem(70);
		width: 100%;
		max-width: 1220px;
		margin: 0 auto;
	}
	&__feedback{
		padding: rem(32) 0 rem(58) 0;
		background-color: #eae1ce;
		background-repeat: no-repeat;
	    background-position: 50% -86%;
    	background-size: 30%;
    	margin-top: rem(38);
	}
	&__feedback-content{
		max-width: 1220px;
		margin: 0 auto;
		width: 100%;
	}
	&__w-feed{
		background-color: $c-lbrown;
	}
	&__text{
		line-height: 30px;
	}
	&__title{
		padding-bottom: 10px;
		margin-bottom: rem(20);
		position: relative;
		&:after{
			position: absolute;
			left: 0;
			bottom: 0;
			right: 0;
			margin: 0 auto;
			content: "";
			width: 70px;
			height: 3px;
			background-color: #dbccac;
		}
	}
}
.feedback-form{
	width: 100%;
	max-width: 800px;
	margin: 0 auto;
	padding: rem(50) 10px rem(60) 10px;
	overflow: hidden;
	@include media($ms){
		overflow: visible;
	}
	
	&__form{
		width: 100%;
		display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	}
	.form__group{
		width: 100%;
	}
	&__group{
		width: 100%;
		display: flex;
		justify-content: space-between;
		display: flex;
		flex-direction: column;
		@include media($md){
			flex-direction: row;
		}
		.form__group{
			width: 100%;
			@include media($md){
				width: calc(50% - 10px);
			}
		}
	}
	&__title{
		color: $c-white;
		text-transform: uppercase;
		letter-spacing: 2px;
		text-align: center;
		margin-bottom: 4px;
	}
	&__subtitle{
		color: $c-white;
		text-transform: uppercase;
		letter-spacing: 4px;
		text-align: center;
		margin-bottom: rem(28);
	}
}
.mfp-close:after, .mfp-close:before {
    width: 2px;
    height: 70%;
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    background-color: #b89b5e;
    -webkit-transform-origin: center;
    transform-origin: center;
    content: "";
    -webkit-transition: background-color .3s ease;
    transition: background-color .3s ease;
}
.mfp-close:before {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.mfp-close:after {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
.news-page{
	margin-bottom: rem(80);
	&__grid{
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin-bottom: rem(60);
	}
}
.page-subtitle{
	text-transform: uppercase;
	letter-spacing: 4px;
	text-align: center;
	color: $c-tgrey;
	margin-bottom: rem(42);
}
.mfp-close{
	width: 54px;
	height: 54px;
	right: 0;
	top: -54px;
	background-color: #574731!important;
	color: #b89b5e!important;
	font-size: 0!important;
	opacity: 1;
	@include media($lg){
		right: -54px;
	}
	&:hover{
		&:after, &:before{
			background-color: $c-green;
		}
	}
	&:active{
		top: -54px;
	}
}
.mails{
	padding: 60px 0;
	background-color: $c-lbrown;
	width: 100%;
	background-repeat: no-repeat;
	background-position: 100% 100%;
	position: relative;
	margin-bottom: 2px;
	&__bg{
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		transform: translateY(-10px);
		width: 102px;
		height: 96px;
		fill: #fafaf9;
	}
	&__grid{
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		position: relative;
		z-index: 1;
	}
	&__item{
		margin: 0 10px;
	}
}
.work-time{
	margin-bottom: rem(80);
	&__text-block{
		margin-bottom: rem(40);
		position: relative;
		b{
			color: $c-dblue;
		}
		&:after{
			position: absolute;
			content: "";
			background-color: #eae1ce;
			left: 0;
			right: 0;
			margin: 0 auto;
			bottom: -20px;
			height: 3px;
			width: 70px;
		}
	}
}
.addres{
	display: flex;
	margin-bottom: rem(50);
	flex-direction: column;
	@include media($def){
		flex-direction: row;
	}
	&__left{
		min-height: 193px;
		border-right: 1px solid $c-white;
		width: 100%;
		background-color: #eae1ce;
		padding: rem(58) 10px;
		position: relative;
		padding-right: 10px;
		order: 2;
		@include media($def){
			 width: 50%;
			 order: 1;
		}
		@include media($lg){
			padding: rem(58);
		  padding-right: 230px;
		 
		}
		@include media($mg){
		    padding-right: rem(58);
		}
		.law-item__bg{
			bottom: 0;
			@include media($lg){
				bottom: rem(38);
			}
		}
		.contacts-title,
		.contacts-text{
			max-width: 374px;
			margin-left: 0;
			margin-right: auto;
			@include media($def){
				margin-left: auto;
			}
		}
		.more-link{
			position: absolute;
			right: 12px;
			bottom:12px;
			z-index: 15;
			@include media($lg){
				bottom: rem(54);
			}
		}
	}
	&__right{
		min-height: 193px;
		border-left: 1px solid $c-white;
		width: 100%;
		background-color: #eae1ce;
		background-repeat: no-repeat;
		position: relative;
		background-position: 100% 100%;
		padding: rem(58) 10px;
		position: relative;
		order: 1;
		@include media($def){
			padding: rem(58);
			width: 50%;
			order: 2;
		}
	}
}
.contacts-title {
	position: relative;
	z-index: 1;
	margin-bottom: rem(16);
}
.contacts-phones{
	margin-bottom: rem(60);
	position: relative;
	&__grid{
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		position: relative;
		z-index: 1;
	}
	&__bg{
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		fill: #f3f2f0;
		transform: translateY(-10px);
		width: 134px;
		height: 128px;
	}
	&__item{
		padding-right: 20px;
		position: relative;
		@include media($md){
			padding-right: rem(60);
		}

		&:after{
			position: absolute;
			right: rem(20);
			content: "//";
			color: $c-green;
			top: 0;
			font-size: 36px;
			font-family: 'Open Sans';
			font-weight: 300;
			display: none;
			@include media($md){
				display: block;
			}
		}
		&:last-of-type{
			&:after{
				display: none;
			}
		}
	}
	&__link{
		display: inline-block;
		color: $c-dblue;
		text-decoration: none;
	}
}
.law-grid{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}
.advantages{
	margin-bottom: rem(6);

	&__grid{
		padding-top: rem(24);
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		@include media($mg){
			justify-content: space-between;
		}
	}
}
.advantages-item{
	padding-left: 88px;
	width: 422px;
	padding-right: 0;
	position: relative;
	margin-bottom: rem(40);
	@include media($md){
		padding-right: 16px;
	}
	&__title{
		padding-bottom: 14px;
		margin-bottom: 16px;
		position: relative;
		line-height: 100%;
	}
	&__text{
		font-weight: 300;
	}
	&__image{
		position: absolute;
		left: 0;
		top: 60px;

		svg{
			position: absolute;
			
			
			&:nth-child(2){
				width: 54px;
				height: 55px;
				fill: $c-brown;
				left: 0;
				top: 0;
			}
			&:nth-child(1){
				width: 105px;
				height: 105px;
				fill: #f3f2f0;
				z-index: -1;
			    left: -4px;
   				top: -17px;
   			    backface-visibility: hidden;
			}
		}
		&:after{
			position: absolute;
			left: 26px;
			bottom: -15px;
			transfrom-origin: left;
			content: "";
			width: 120px;
			height: 2px;
			background-color: $c-brown;
		}
	}
}
.footer-nav{
	padding: 0;
	margin: 0;
	list-style-type: none;
	&__title{
		color: $c-dblue;
		text-transform: uppercase;
		margin-bottom: rem(44);
		padding-bottom: rem(44);
		position: relative;
		&:after{
			position: absolute;
			bottom: 0;
			left: 0;
			height: 2px;
			width: 34px;
			background-color: $c-brown;
			content: "";
		}
	}
	&__item{
		margin-bottom: 6px;
	}
	&__link{
		font-size: 14px;
		font-family: 'Open Sans';
		color: $c-tgrey;
		text-decoration: none;
		padding: 6px 0;
		display: inline-block;
		transition: color 0.5s ease;
		position: relative;
		&:after{
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 1px;
			transition: transform 0.5s ease;
			background-color: $c-dblue;
			content: "";
			transform: scaleX(0);
			transform-origin: left;
		}
		&:hover{
			color: $c-dblue;
			&:after{
				transform: scaleX(1);
			}
		}
	}
}
.socs-item{
	width: 50px;
	height: 50px;
	margin: 0 10px;
	position: relative;
	border-radius: 100%;
	svg{
		fill: $c-white;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		margin: auto;
	}
	&--fb{
		background-color: #3262a4;
		svg{
			width: 12px;
			height: 22px;
		}
	}
	&--vk{
		background-color: #4da1bc;
		svg{
			width: 22px;
			height: 12px;
		}
	}
	&--inst{
		background-color: #d6226a;
		svg{
			width: 24px;
			height: 24px;
		}
	}
}
#seo_text{
	margin-bottom: rem(40);
}
.socs-block{
	display: flex;
	margin: 16px 0;
	@include media($def){
		margin: 0;
	}
}
.copy{
	color: $c-tgrey;
	font-size: 12px;
	text-transform: uppercase;
	letter-spacing: 1px;
	text-align: center;
	@include media($def){
		text-align: left;
	}
}
.wezom{
	text-align: center;
	@include media($def){
		text-align: left;
	}
	&__link{
		color: $c-tgrey;
		font-size: 12px;
		text-transform: uppercase;
		letter-spacing: 1px;
		text-decoration: none;
		&:hover{
			color: red;
			svg{
				fill: red;
			}
		}
	}
	svg{
		transition: fill 0.3s ease;
		width: 30px;
		height: 10px;
		fill: #343434;
	}

}
.law-item{
	width: 320px;
	height: 166px;
	margin: 14px;
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
	border: 1px solid #dfdeda;
	text-decoration: none;
	position: relative;
	transition: border-color 0.3s 0.2s ease, transform 1.2s ease;
	backface-visibility: hidden;

	&__text{
		text-align: center;
		line-height: 100%;
		color: $c-dblue;
		max-width: 220px;
		transition: transform 0.5s ease, color 0.4s ease;
	}
	&--services{
		max-width: 200px;
		height: 144px;
		@include media($mg){
			max-width: 240px;
		}
	}
	&__bg{
		position: absolute;
		bottom: 0;
		right: 0;
		height: 60px;
		width: 180px;
		fill: #f3f2f0;
		transition: fill 0.5s ease;
		z-index: -1;
		@include media($md){
			z-index: 1;
		}
	}
	&:before{
		background-color: #dfdeda;
		top: 0;
		left: 0;
		right: 0;
		margin: 0 auto;
		width: 1px;
		height: 40px;
		content: "";
		position: absolute;
		transition: transform 0.5s ease;
	}
	&:after{
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		content: "";
		transform: scale(1);
		opacity: 0;
		background-color: $c-brown;
		transition: opacity 0.5s ease, transform 0.5s ease;
		z-index: -1;
	}
	&:hover{
		border-color: transparent;
		transition: border-color 0s 0s ease;
		&:after{
			opacity: 1;
			transform: scaleX(1.08) scaleY(1.14);
		}
		&:before{
			transform: translateY(-12px);
		}
		.law-item__text{
			color: $c-white;
			transform: translateY(-12px);
		}
		.law-item__bg{
			fill: #9c885b;
		}
		.more-link--law{
			opacity: 1;
		}
	}
}
.feed-form{
	display: flex;
	.form__group{
		margin-bottom: 0;
		width: 234px;
	}
	input{
		padding-right: 30px;
		height: 40px;
		border-right: none;
		background-color: $c-white;
	}
	&__content-holder{
		width: 122px;
		display: flex;
		justify-content: center;
		align-items: center;
		letter-spacing: 2px;
		position: relative;
		z-index: 2;
		@include media($ms){
			width: 190px;
		}
	}
	&__btn{
		border: 0;
		outline: none;
		background-color: transparent;
		padding: 0;
		text-transform: uppercase;
		background-color: $c-green;
		color: $c-white;
		height: 40px;
		position: relative;
		line-height: 40px;
		&:before{
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			content: "";
			background-color: $c-brown;
			z-index: 1;
			transform: rotateY(90deg);
			opacity: 0;
			transition: opacity 0.6s ease-in-out, transform 0s 0.6s ease, background-color 0.3s ease;
		}
		&:hover{
			&:before{
				transform: rotateY(0deg);
				transition: transform 0.3s ease-in-out, opacity 0s ease, background-color 0.3s ease;
				opacity: 1;
			}
		}
		&:active{
			&:before{
				transform: rotateY(0deg);
				opacity: 1;
				background-color: darken($c-brown, 10%);
				transition: all 0s ease;
			}
		}
	}
}
.block-title-wrapper{
	text-align: center;
	margin-bottom: 16px;
}
.block-title{
	text-transform: uppercase;
	color: $c-green;
	letter-spacing: 1px;
	margin-bottom: 4px;
	display: inline-block;
	line-height: 100%;
	text-decoration: none;
}
.index-news{
	margin-bottom: rem(60);

	&__grid{
		display: flex;
		width: 100%;
		position: relative;
		left: 0;
		padding-top: rem(30);
		@include media($def){
			width: 2060px;
		}
	}
}
.news-item{
	width: 320px;
	border: 1px solid #dfdeda;
	display: flex;
	flex-direction: column;
	text-decoration: none;
	position: relative;
	transition: border-color 0.3s 0.2s ease;
	margin: 10px;

	@include media($def){
		width: 360px;
		margin: rem(20) 26px;
	}

	&__image{
		
	}
	&__descr{
		position: relative;
		padding: rem(34px);
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		align-content: center;
		flex-direction: column;
	}
	&__date{
		text-align: center;
		margin-bottom: rem(16);
		color: $c-tgrey;
	}
	&__text{
		text-align: center;
		color: $c-dblue;
		height: 60px;
		overflow: hidden;
		line-height: 100%;
		margin-bottom: rem(40);
	}
	.more-link__text{
		color: $c-brown;
	}
	.more-link--law .more-link__image-holder:before{
		background-color: $c-brown;
	}
	.more-link--law .more-link__arrow{
		fill: $c-brown;
	}
	&:after{
		position: absolute;
		content: "";
		width: 100%;
		height: 100%;
		background-color: $c-white;
		z-index: -1;
		left: 0;
		top: 0;
		box-shadow: 0px 9px 45px 2px rgba(128,128,128,0.76);
		opacity: 0;
		transition: opacity 0.5s ease, transform 0.5s ease;
		display: none;
		@include media($def){
			display: block;
		}
	}
	&:hover{
		border-color: transparent;
		transition: border-color 0.2s 0s ease;
		&:after{
			opacity: 1;
			transform: scaleX(1.05) scaleY(1.05);
		}
		.more-link--law{
			opacity: 1;
		}
		.law-item__bg{
			fill: #f1ebdf;
		}
	}
}
.abus-items{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	@include media($def){
		flex-wrap: nowrap;
	}
}
.abus-index{
	margin-bottom: rem(20);
	@include media($def){
		margin-bottom: rem(60);
	}
}
.abus-content{
	display: flex;
	padding: rem(20) 0;
	flex-wrap: wrap;
	@include media($xl){
		flex-wrap: nowrap;
	}
}
.abus-item{
	display: flex;
	flex-direction: column;
	padding: 0 8px;
	max-width: 360px;
	min-width: 250px;
	margin-bottom: rem(40);
	@include media($def){
		margin-bottom: 0;
	}
	&__title{
		color: $c-dblue;
		max-width: 130px;
		line-height: 24px;
		margin-bottom: 18px;
		padding-bottom: 18px;
		position: relative;

		&:before{
			width: 40px;
			height: 40px;
			background-color: #f8f6f2;
			bottom: 10px;
			z-index: -1;
			left: 0;
			content: "";
			position: absolute;
		}
		&:after{
			width: 60px;
			height: 2px;
			background-color: $c-brown;
			bottom: 0;
			left: -20px;
			content: "";
			position: absolute;
		}
	}
	&__text{
		color: $c-dark;
		font-weight: 300;
	}
}
.hamburger{
	display: block;
	@include media($def){
		display: none;
	}
}
.hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
    background-color: $c-dblue;
}
.page-title{
	color: $c-green;
	text-align: center;
	text-transform: uppercase;
}
.abus-int{
	max-width: initial;
	margin-right: 0;
	width: 100%;
	text-align: center;
	margin-bottom: 20px;
	@include media($xl){
		margin-bottom: 0;
		margin-right: 4%;
		max-width: 280px;
		text-align: left;
	}
	&__title{
		color: $c-dblue;
		line-height: 100%;
		margin-bottom: 10px;
		@include media($xl){
			margin-bottom: rem(50);
		}
	}
}
.search-resp,
.sort-resp{
	cursor: pointer;
	display: block;
	position: relative;
	margin: 0 8px;
	svg{
		width: 32px;
		height: 32px;
		fill: $c-dblue;
	}
}
.resp-group{
	position: absolute;
	right: 2rem;
	top: 6px;
	display: flex;
	@include media($def) {
		display: none;
	}
}
.block-subtitle{
	color: $c-tgrey;
	text-transform: uppercase;
	letter-spacing: 3px;
}
.lng-block{
	display: none;
	@include media($def){
		display: flex;
	}
}
.logo-resp{
	display: flex;
	transform: translateX(-20px);
	@include media($ms){
		transform: translateX(0);
	}
	@include media($def){
		display: none;
	}
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	margin: 0 auto;
	width: 202px;
	z-index: 5;
	align-items: center;
	justify-content: center;
	@include media($ms){
		top: -4px;
	}

	img{
		width: auto;
		height: 58px;
		@include media($ms){
			height: 66px;
		}
	}
}
.lng-item{
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
	margin: 0 10px;
	position: relative;
	&:first-of-type{
		margin-left: 0;
	}
	&:last-of-type{
		margin-right: 0;
	}
	&__link{
		display: flex;
		justify-content: center;
		letter-spacing: 3px;
		align-items: center;
		padding: 10px 6px;
		width: 36px;
		text-decoration: none;
		text-transform: uppercase;
		color: $c-brown;
		position: relative;
		z-index: 3;
		&:hover{
			color: $c-green;
		}
	}
	&.is-active{
		user-select: none;
		pointer-events: none;
		.lng-item__link{
			color: $c-white;
			&:after{
				position: absolute;
				content: "";
				left: 0;
				top: 100%;
				height: 18px;
				width: 100%;
				border-radius: 0 0 0 0;
				background-color: $c-brown;
				display: none;
				@include media($def){
					display: block;
				}

			}
			&:before{
				position: absolute;
				top: calc(100% + 10px);
				left: 0;
				right: 0;
				margin: 0 auto;
				width: 18px;
				background-color: $c-white;
				height: 1px;
				content: "";
				z-index: 2;
				display: none;
				@include media($def){
					display: block;
				}

			}
		}
		&:before{
			background-color: $c-brown;
			position: absolute;
			content: "";
			z-index: 1;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			
		}
		&:after{
			width: 0;
			height: 0;
			border-top: 30px solid $c-brown; 
			border-right: 36px solid transparent;
			position: absolute;
			top: calc(100% + 18px);
			left: 0;
			content: "";
			display: none;
				@include media($def){
					display: block;
				}
		}
	}
}
.search-grid{
	// border-top: 1px solid #d3d3d1;
	margin-bottom: rem(36);
}
.search-item{
	width: 100%;
	// border-left: 1px solid #d3d3d1;
	// border-right: 1px solid #d3d3d1;
	// border-bottom: 1px solid #d3d3d1;
	border: 1px solid #d3d3d1;
	text-decoration: none;
	cursor: pointer;
	position: relative;
    transition: border-color 0.3s 0.2s ease;
	display: flex;
	padding: 12px;
	@include media($md){
		padding: 0;
	}
	&__number{
		width: 100%;
		font-size: rem(200);
		font-weight: 200;
		font-family: 'RistrettoPro';
		flex-shrink: 0;
		width: 100px;
		position: relative;
		color: #dbcdae;
		overflow: hidden;
		display: none;
		transition: color 0.5s ease;
		@include media($md){
			display: block;
		}
		span{
			z-index: 5;
			position: relative;
			display: inline-block;
		}
		&:before {
	    	position: absolute;
		    left: -83px;
		    top: 38px;
		    width: 130px;
		    height: 130px;
		    content: "";
		    background-color: transparent;
		    border-top: 2px solid #b89b5e;
		    border-left: 2px solid #dbcdae;
		    -webkit-transform: rotate(130deg);
		    transform: rotate(130deg);
		}
	}
	&__descr{
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	&__title{
		color: $c-dblue;
		margin-bottom: 20px;
	}
	&__text{
		color: $c-dblue;
	}
	&:after{
		position: absolute;
	    content: "";
	    width: 100%;
	    height: 100%;
	    background-color: #fff;
	    z-index: -1;
	    left: 0;
	    top: 0;
	    box-shadow: 0px 9px 45px 2px rgba(128, 128, 128, 0.76);
	    opacity: 0;
	    transition: opacity 0.5s ease, transform 0.5s ease;
	}
	.more-link__image-holder{
		opacity: 0;
		top: initial;
		bottom: 10px;
		right: 10px;
		transition: opacity 0.5s ease;
		z-index: 5;
	}
	&:hover{
	    transition: border-color 0.2s 0s ease;
		z-index: 15;
		border-color: transparent;
		.search-item__number{
			color: $c-brown;
		}
		&:after{
		    opacity: 1;
	        transform: scaleX(1.02) scaleY(1.12);
		}
		.law-item__bg{
			fill: #f1ebdf;
		}
		.more-link__image-holder{
			opacity: 1;
		}
	}
}
.search-sum{
	margin-bottom: rem(60);
	color: $c-dblue;
	&__sum{
		color: $c-brown;
	}
}
.services{
	margin-bottom: rem(50);
}
.services-name{
	display: block;
	background-color: $c-green;
	width: 100%;
	flex-shrink: 0;
	margin: 14px 0;
	min-height: 112px;
	position: relative;
	padding-top: 42px;
	backface-visibility: hidden;
	@include media($md){
		width: 200px;
		min-height: 144px;
	}
	@include media($mg){
		width: 242px;
	}
	&__text{
		color: $c-white;
		text-align: center;
	}
	&:after{
		position: absolute;
		width: 1px;
		background-color: $c-white;
		height: 40px;
		content: "";
		left: 0;
		right: 0;
		top: 0;
		margin: 0 auto;
	}
}
.services-block{
	display: flex;
}
.services-group{
	display: flex;
	flex-direction: column;
	@include media($md){
		flex-direction: row;
	}
	&__items{
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		@include media($md){
			justify-content: flex-start;
		}
	}
}
.left-menu{
	margin-right: 0;
	width: 310px;
	flex-shrink: 0;
	position: fixed;
	transform: translateX(-240%);
	transition: transform 0.8s ease;
	z-index: 15;
	padding-right: 10px;
	top: 58px;
	left: 0;
	height: 100%;
	padding-left: 16px;
	background-color: $c-white;
	@include media($def){
		padding-right: 0;
		margin-right: 14px;
		display: block;
		padding-left: 0;
		transform: translateX(0%);
		position: relative;
	}
	@include media($mg){
		margin-right: 34px;
		width: 340px;
	}
	&.is-active{
		transform: translateX(0%);
	}
	
	&__list,
	&__list-sub{
		list-style-type: none;
		padding: 0;
		margin: 0;
	}
	&__link{
		height: 60px;
		display: flex;
		align-items: center;
		text-decoration: none;
			cursor: pointer;
			position: relative;
	}
	&__list{
		& > .left-menu__item{
			& > .left-menu__link{
				transition: color 0.5s ease;
				border-bottom: 2px solid #f1ebdf;
				&:hover{
					color: $c-green;
					&:after{
						background-color: $c-green;
					}
				}
				&:after{
					position: absolute;
					left: 0;
					bottom: -2px;
					content: "";
					height: 2px;
					width: 100%;
					transform: rotateY(86deg);
					transform-origin: left;
					transition: background-color 0.5s ease, transform 0.6s ease;
					background-color: $c-brown;
				}
				&.is-active{
					color: $c-green;
					&:after{
						transform: rotateY(0);
						background-color: $c-green;
					}
				}
			}
			& > .left-menu__list-sub{
				border-bottom: 1px solid $c-green;
			}
		}
	}
	&__item{
		& > .left-menu__link{
			font-size: rem(28);
			font-weight: 500;
			font-family: 'RistrettoPro';
		}
		& > .left-menu__list-sub{
			& > .left-menu__list-sub-item{
				& > .left-menu__link{
					padding-left: 20px;
					font-size: rem(24);
					font-family: 'RistrettoPro';
					color: $c-dblue;
					font-weight: 500;
					transition: color 0.5s ease;
					border-bottom: 1px solid #e1dbcd;
					&:hover{
						color: $c-green;
					}
					&.is-active{
						color: $c-green;
					}
				}

				& > .left-menu__list-sub{
					& > .left-menu__list-sub-item{
						.left-menu__link{
							font-family: 'Open Sans';
							position: relative;
							background-color: #e1dbcd;
							color: $c-dblue;
							padding-left: 40px;
							font-size: 14px;
							transition: background-color 0.5s ease, color 0.3s ease;
							margin-bottom: 1px;
							&:before{
								position: absolute;
								z-index: 1;
								content: "";
								left: 20px;
								top: 0;
								bottom: 0;
								margin: auto 0;
								background-color: $c-dblue;
								height: 1px;
								width: 10px;
								transition: background-color 0.5s ease;
							}
							&.is-active{
								background-color: $c-brown;
								color: $c-white;
								&:after{
									background-color: $c-brown;
								}
								&:before{
									background-color: $c-white;
								}
							}
							&:hover{
								background-color: $c-brown;
								color: $c-white;
								&:after{
									background-color: $c-brown;
								}
								&:before{
									background-color: $c-white;
								}
							}
						}
					}
				}
			}
		}
	}
}
.nav{
	display: none;
	@include media($def){
		display: flex;
	}
	&__link{
		text-transform: uppercase;
		text-decoration: none;
		color: $c-brown;
		padding: 0 rem(4);
		margin: 0 rem(8);
		position: relative;
		transition: color 0s 0s ease;
		letter-spacing: normal;
		@include media ($lg){
			margin: 0 rem(16);
			letter-spacing: 2px;
		}
		&:hover{
			color: transparent;
			transition: color 0.3s 0.3s ease;
			&:after{
				transform: scale(1);
			}
			span{
				&:before{
					width: 100%;
				}
			}
		}
		span{
			position: relative;
			display: inline-block;
			&:before{
			    content: attr(data-attr-text);
			    display: block;
			    position: absolute;
			    top: 0;
			    left: 0;
			    width: 0;
			    height: 100%;
			    color: $c-green;
			    transition: width .4s ease-out;
			    overflow: hidden;
			    font-family: 'RistrettoPro';
			    font-size: rem(28);
			    font-weight: 600;
			    white-space: nowrap;
			}
		}
		&:after{
			bottom: 0;
			left: rem(4);
			width: 22px;
			height: 1px;
			background-color: $c-green;
			position: absolute;
			content: "";
			transform-origin: left;
			transform: rotateY(90deg);
			transition: transform 0.5s ease;
		}
	}
}
.prices{
	&__bottom{
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		padding-top: rem(30);

		.btn--big{
			padding: 0 12px;
			@include media($mg){
				padding: 0 42px;
			}
		}
		&--service{
			justify-content: space-between;
			padding-top: rem(40);
			margin-bottom: 0;
			flex-direction: column;
			@include media($md){
				flex-direction: row;
			}
			.prices__text{
				text-align: left;
			}
		}
	}
	&__text{
		color: $c-dblue;
		line-height: 100%;
		width: 100%;
		max-width: 590px;
		text-align: center;
		margin-bottom: rem(24);
	}
	margin-bottom: rem(52);
}
.m-menu__lngs{
	display: flex;
	justify-content: space-around;
}
.m-menu__lng{
	color: $c-brown!important;
	font-size: 1.55rem!important;
    font-weight: 600!important;
}
.m-menu__link{
	color: $c-brown!important;
	font-size: 1.25rem!important;
    font-weight: 600!important;
}
.m-menu__item{
	border-color: $c-green!important;
}
.mm-title{
	color: $c-green!important;
	font-size: 1.75rem!important;
    font-weight: 600!important;
}
.mm-menu{
	background-color: $c-white!important;
}
.main-slider-wrapper{
	position: relative;
	margin-bottom: 	44px;
	overflow: hidden;

	&__bg{
		position: absolute;
		left: 0;
		top: 0;
	    width: 1150px;
    	height: 690px;
		z-index: 10;
		fill: #001f1a;
		opacity: 0.7;
		display: none;
		transform: translateX(-730px) rotateY(180deg);
		    border-radius: 0 0 0 520px;
		@include media($def){
		    display: block;
		}
		@include media($lg){
		    transform: translateX(-312px);
		    border-radius: 0;
		}
		@include media($mg){
		    transform: translateX(0);
		}
	}
}

.main-slider{
	position: relative;
	height:500px;
	@include media($md){
		height: 742px!important;
	}
	.btn--big{
		width: 86%;
		max-width: 310px;
		@include media($md){
			width: 100%;
		}
	}
	&__item{
		position: relative;
		outline: none;
		border: none!important;
		height:500px!important;
		width: 100%;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: 90%;
		@include media($md){
			background-position: 100%;
			height: 742px!important;
		}
		&:after{
			position: absolute;
			content: "";
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			opacity: 0.9;
			z-index: 5;
			display: none;
			@include media($md){
				display: block;
				background: linear-gradient(90deg, rgba(0, 31, 26, 0.6), rgba(0, 31, 26, 0.4), rgba(0, 31, 26, 0.4), rgba(0, 31, 26, 0.2), transparent, transparent);
			}
			@include media($def){
				background: linear-gradient(90deg, rgba(0, 31, 26, 1), rgba(0, 31, 26, 0.8), rgba(0, 31, 26, 0.6), rgba(0, 31, 26, 0.4), transparent, transparent);
			}
		}
	}
	.title{
		height: 41px;
		font-size: 28px;
		@include media($md){
			font-size: 3rem;
		}
	}
	&__item-content{
		position: absolute;
		bottom: 0;
		padding-bottom: 16px;
		left: 0;
		right: 0;
		padding-top: 6px;
		margin: 0 auto;
		margin-left: 0;
		width: 100%;
		max-width: 100%;
		z-index: 15;
		color: $c-white;
		text-align: center;
		opacity: 0;
		transition: opacity 0.6s ease, transform 0s 0.6s ease;
		transform: translateY(200px);
		background-color: rgba(0, 31, 26, 0.6);
		@include media($md){
			padding-bottom: 0;
			max-width: 620px;
			margin-left: 20px;
			padding-top: 0;
			background-color: transparent;
			bottom: 16px;
		}
		@include media($def){
			margin-left: auto;
		}
		@include media($lg){
			bottom: 50px;
		}
	}
	&__item-text{
		line-height: 20px;
		font-size: 16px;
		font-family: 'Open Sans';
		padding-top: 16px;
		margin-bottom: rem(22);
		height: 90px;
		padding-bottom: 8px;
		overflow: hidden;
		font-weight: 300;
		@include media($md){
			font-size: 18px;
			height: 74px;
		}
	}
	&:after{
		position: absolute;
		bottom: 0;
		right: 0;
		width: 0;
		height: 0;
		border-bottom: 270px solid $c-brown; 
		border-left: 270px solid transparent;
		opacity: 0.6;
		content: "";
		z-index: 1;
		display: none;
		@include media($md){
			display: flex;
		}
	}
}
.slick-slide img{
	width: 1820px;
}
.slick-active{
	.main-slider__item-content{
		opacity: 1;
		transition: opacity 0.8s 0.6s ease, transform 1.2s 0.6s ease;
		transform: translateY(0);
	}
}
.slider-nav{
	position: absolute;
	left: 0;
	top: 30px;
	width: 100%;
	z-index: 15;
	max-height: calc(100% - 126px);
	overflow: hidden;
}
.slick-dots{
	position: absolute;
	bottom: 26px;
	right: 0;
	justify-content: center;
	padding: 0;
	align-items: center;
	list-style-type: none;
	z-index: 5;
	display: none!important;
	@include media($md){
		display: flex!important;
	}

	button{
		width: 10px;
		background-color: $c-white;
		height: 6px;
		font-size: 0;
		outline: none;
		border: none;
		position: absolute;
		left: 0;
		top: -2px;
		transition: width 0.3s ease;
		&:active{
			
		}
	}
	li{
		height: 6px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		margin: 0 15px;
		width: 50px;
		position: relative;
		border-top: 2px solid $c-white;
		background-color: transparent;
		&:hover{
			button{
				width: 50%;
			}
		}
	}
	.slick-active{
		cursor: default;
		pointer-events: none;
		button{
			width: 100%;
		}
	}
}

.phone-block{
	display: flex;
	margin-left: 10px;
	flex-direction: column;
	a{
		color: $c-white;
		text-decoration: none;
		font-weight: 700;
		&:hover{
			color: $c-brown;
		}
	}
}
.feed-block{
	&__title{
		margin-bottom: 10px;
		color: $c-green;
	}
}
.sub-phones{
	&__del{
		color: $c-green;
		margin: 0 2px;
	}
	&__item{
		text-decoration: none;
		font-weight: bold;
		&:hover{
			color: $c-brown;
			transition: color 0.3s ease;
		}
	}
}
.sub-nav{
	position: relative;
	display: none;
	@include media($def){
		display: block;
	}
	&__info-logo{
		display: none;
		@include media($md){
			display: block;
		}
	}
	&__list{
		width: 100%;
		list-style-type: none;
		padding: 0;
		margin: 0;
		max-width: 360px;
		position: relative;
		overflow: overlay;
	}
	&__info-block{
		display: flex;
		margin-bottom: 12px;
		img{
			display: none;
			@include media($lg){
				display: block;
			}
		}
	}
	&__item{
		padding: 4px 0;
		.sub-nav__list{
			padding-left: 20px;
			margin-top: 4px;
			.sub-nav__link{
				&:before{
					font-size: rem(24);
				}
			}
			
			&:after,
			&:before{
				position: absolute;
				content: "";
				width: 100%;
				background-color: $c-white;
				left: 0;
				transform: rotateY(90deg);
				transform-origin: center;
			}
			&:before{
				height: 2px;
				top: 0;
				transition: transform 0.8s 0s ease;
			}
			&:after{
				height: 1px;
				bottom: 0;
				transition: transform 0.8s 0s ease;
			}
		}
		&.js-active{
			& > .sub-nav__link{
				color: $c-white;
				pointer-events: none;
			}
			.sub-nav__list{
				&:after,
				&:before{
					transform: rotateY(0deg);
				}
			}
		}
	}
	&__link{
		color: $c-brown;
		text-decoration: none;
		position: relative;
		transition: color 0s 0s ease;

		&:before{
		    content: attr(data-attr-text);
		    display: block;
		    position: absolute;
		    top: 0;
		    left: 0;
		    width: 0;
		    height: 100%;
		    color: $c-white;
		    transition: width .4s ease-out, color 0.3s ease;
		    overflow: hidden;
		    font-family: 'RistrettoPro';
		    font-size: rem(28);
		    font-weight: 500;
		    white-space: nowrap;
		}
		&:hover{
			color: transparent;
			transition: color 0.3s 0.3s ease;

			&:before{
				width: 100%;
			}
		}
		&:active{
			&:before{
				color: darken($c-white, 15%);
				transition: color 0s 0s ease;
			}
		}
	}
}
.tnx-feed-slider-wrapper{
	width: 100%;
	background-repeat: no-repeat;
	background-position: 0 0%;
	background-size: cover;
	position: relative;
}
.slick-active{
	.tnx-feed-slider__item-name{
		opacity: 1;
		transition: opacity 0.4s 0.6s ease;
	}
}
.tnx-feed{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 44px 0;
    background-position: 80% 0;
    background-repeat: no-repeat;
    background-size: cover;
}
.tnx-feed-slider{
	padding-bottom: 0;
	width: 100%;
	@include media($def){
		padding-bottom: rem(64);
	}
	&__item{
		padding-top: rem(60);
		display: flex!important;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		outline: none;
		cursor: default;
	}
	&__item-text{
		text-align: center;
		width: 100%;
		height: 200px;
		overflow: hidden;
		margin-bottom: rem(40);
		max-width: 1100px;
		padding: 0 10px;
		font-weight: 300;
		@include media($md){
			height: 102px;
		}
	}
	&__item-name{
		text-align: center;
		width: 100%;
		max-width: 1100px;
		opacity: 0;
		transition: opacity 0.2s 0s ease;
	}
	
	&__arrow-wrapper{
		position: absolute;
		bottom: 1rem;
		z-index: 55;
		cursor: pointer;
		left: 0;
		right: 0;
		width: 52px;
		margin: 0 auto;
		display: none;
		@include media($md){
			display: block;
		}
		@include media($def){
			bottom: rem(80);
		}

		&--left{
			transform: rotate(180deg) translateX(250px);
		}
		&--right{
			transform: translateX(250px);
		}
		&:hover{
			.tnx-feed-slider__image-holder{
				&:before{
					animation: lines_hover_animation 0.5s alternate;
				}
				.tnx-feed-slider__arrow{
					animation: blink 0.8s alternate;
				}
			}
		}
	}
	&__image-holder{
		width: 86px;
		height: 52px;
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto 0;
		overflow: hidden;
		&:before{
			top: 0;
			bottom: 0;
			margin: auto 0;
			right: 26px;
			height: 1px;
			content: "";
			position: absolute;
			width: 64px;
			background-color: $c-brown;
			z-index: -1;
		}
	}
	&__arrow,
	&__square{
		position: absolute;
		top: 0;
		fill: $c-brown;
	}
	&__square{
		width: 52px;
		height: 52px;
		opacity: 0.3;
		right: 0;
	}
	&__arrow{
		width: 18px;
		height: 34px;
		right: 16px;
		bottom: 0;
		margin: auto;
	}
	
}
.work-scheme{
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 50px 0;
	background-position: 0 100%;
	background-repeat: no-repeat;
	background-size: cover;

	&__item-number{
		font-size: rem(200);
		color: $c-brown;
		position: absolute;
		left: 24px;
		top:-16px;
		line-height: 100%;
		font-size: 7.5rem;
		@include media($md){
			font-size: 12.5rem;
			top: -40px;
		}
		span{
			position: relative;
			z-index: 1;
		}
		&:after{
			position: absolute;
    	    left: -38px;
   			top: 18px;
			width: 60px;
			height: 60px;
			content: "";
			background-color: transparent;
			border-top: 2px solid #4b5545;
			display: none;
			border-left: 2px solid transparent;
			transform: rotate(130deg);
			@include media($md){
			    width: 130px;
			    height: 130px;
			    left: -98px;
			    top: 39px;
			    display: block;
			}
		}
		&:before{
			position: absolute;
    	    left: -38px;
   			top: 18px;
			width: 60px;
			height: 60px;
			content: "";
			background-color: transparent;
			border-top: 2px solid $c-brown;
			border-left: 2px solid #6c6a4d;
			transform: rotate(130deg);
			@include media($md){
				width: 130px;
				height: 130px;
				left: -83px;
    			top: 34px;
			}
		}
	}
	&__grid{
		display: flex;
		margin-bottom: rem(40);
		margin-top: rem(64);
		flex-wrap: wrap;
		justify-content: space-around;
		@include media($mg){
			font-weight: normal;
		}
	}
	&__item{
		padding-left: 60px;
		position: relative;
		width: initial;
		min-width: 310px;
		margin-bottom: rem(60);
		max-width: 100%;
		@include media($md){
			width: 350px;
			padding-left: 120px;
		}
		@include media($mg){
			width: initial;
		}
	}
	&__item-text{
		color: $c-white;
		width: 230px;
	}
}
.inner-news-page{
	.view-text{
		margin-bottom: rem(42);
	}
}
.widget-block{
	margin-bottom: rem(82);
	display: flex;
	align-items: center;
	&__title{
		margin-right: 8px;
		color: $c-dblue;
	}
}
.date-block{
	padding-bottom: rem(28);
	padding-top: rem(20);
	margin-bottom: rem(20);
	position: relative;
	&:after{
		position: absolute;
		left: 0;
		right: 0;
		margin: 0 auto;
		width: 70px;
		height: 3px;
		background-color: #eae1ce;
		content: "";
		bottom: 0;
	}
}
.mCSB_dragger_bar{
    background-color: #aaa;
    transition: transform 0.5s ease, background-color 0.5s ease!important;
}
.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar{
    background-color: #999;
    transform: scaleX(1.5);
}
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{
	background-color: #aaa;
}
.mCSB_scrollTools .mCSB_draggerRail{
	background-color: transparent;
}
.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
	.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar{
		background-color: #aaa;
	}
.mCSB_scrollTools{
    width: 10px;
}
.mCSB_inside > .mCSB_container {
    margin-right: 10px;
}


@import '_partials/_halpers';