@media only screen and (min-width: 320px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 375px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 412px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 480px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 640px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 768px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 1025px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 1280px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 1366px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 1440px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 1439px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 1365px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 1279px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 1024px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 767px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 639px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 479px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 411px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 374px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 319px) {
  /*! $mqpacker hack */
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-size: 100%;
}

body {
  margin: 0;
}

article, aside, footer, header, nav, section {
  display: block;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

figcaption,
figure {
  display: block;
}

figure {
  margin: 1em 0;
}

main {
  display: block;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  font-size: 1em;
}

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: inherit;
}

b, strong {
  font-weight: bold;
}

code, kbd, samp {
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

mark {
  background-color: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

audio, video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

button, input, optgroup, select, textarea {
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  display: inline-block;
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details,
menu {
  display: block;
}

summary {
  display: list-item;
}

canvas {
  display: inline-block;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

a:active, a:hover {
  outline-width: 0;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

abbr[title], dfn[title] {
  cursor: help;
}

button {
  cursor: pointer;
}

button:disabled {
  cursor: not-allowed;
}

input::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input[readonly] {
  cursor: default;
}

input:disabled {
  cursor: not-allowed;
}

html {
  position: relative;
  height: 100%;
  font-size: 12px;
}

@media only screen and (min-width: 768px) {
  html {
    font-size: 14px;
  }
}

@media only screen and (min-width: 1280px) {
  html {
    font-size: 16px;
  }
}

*, :after, :before {
  box-sizing: inherit;
}

a {
  color: #43f;
}

.view-wrapper {
  padding-top: 56px;
}

@media only screen and (min-width: 1025px) {
  .view-wrapper {
    padding-top: 0;
  }
}

body {
  position: relative;
  height: 100%;
  box-sizing: border-box;
  min-width: 320px;
}

pre {
  padding: 1.2em;
  tab-size: 4;
  border-width: 0;
  white-space: pre;
  white-space: pre-wrap;
  background: #eee;
  word-wrap: break-word;
}

img {
  max-width: 100%;
  height: auto;
}

small {
  display: inline-block;
  vertical-align: text-bottom;
}

svg {
  pointer-events: none;
}

hr {
  border-width: 0;
  border-top: 1px solid #aaa;
}

::selection {
  text-shadow: none;
  color: #fff;
  background: #2597ff;
}

::-moz-selection {
  text-shadow: none;
  color: #fff;
  background: #2597ff;
}

::-ms-selection {
  text-shadow: none;
  color: #fff;
  background: #2597ff;
}

input {
  box-shadow: none;
}

input::-webkit-input-placeholder {
  color: #999;
}

input::-moz-placeholder {
  color: #999;
  opacity: .5;
}

input:-ms-input-placeholder {
  color: #999;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}

input:focus::-moz-placeholder {
  color: transparent;
}

input:focus:-ms-input-placeholder {
  color: transparent;
}

textarea {
  box-shadow: none;
}

textarea::-webkit-input-placeholder {
  color: #999;
}

textarea::-moz-placeholder {
  color: #999;
  opacity: .5;
}

textarea:-ms-input-placeholder {
  color: #999;
}

textarea:focus::-webkit-input-placeholder {
  color: transparent;
}

textarea:focus::-moz-placeholder {
  color: transparent;
}

textarea:focus:-ms-input-placeholder {
  color: transparent;
}

@font-face {
  font-family: 'RistrettoPro';
  src: url("fonts/subset-RistrettoPro-ExtraLight.woff2") format("woff2"), url("fonts/subset-RistrettoPro-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'RistrettoPro';
  src: url("fonts/subset-RistrettoPro-Thin.woff2") format("woff2"), url("fonts/subset-RistrettoPro-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'RistrettoPro';
  src: url("fonts/subset-RistrettoPro-SemiBold.woff2") format("woff2"), url("fonts/subset-RistrettoPro-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'RistrettoPro';
  src: url("fonts/subset-RistrettoPro-Bold.woff2") format("woff2"), url("fonts/subset-RistrettoPro-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'RistrettoPro';
  src: url("fonts/subset-RistrettoPro-Medium.woff2") format("woff2"), url("fonts/subset-RistrettoPro-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'RistrettoPro';
  src: url("fonts/subset-RistrettoPro-Light.woff2") format("woff2"), url("fonts/subset-RistrettoPro-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'RistrettoPro';
  src: url("fonts/subset-RistrettoPro-Black.woff2") format("woff2"), url("fonts/subset-RistrettoPro-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'RistrettoPro';
  src: url("fonts/subset-RistrettoPro-Regular.woff2") format("woff2"), url("fonts/subset-RistrettoPro-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url("fonts/subset-OpenSans.woff2") format("woff2"), url("fonts/subset-OpenSans.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url("fonts/subset-OpenSansBold.woff2") format("woff2"), url("fonts/subset-OpenSansBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url("fonts/subset-OpenSansLight.woff2") format("woff2"), url("fonts/subset-OpenSansLight.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

.view-wrapper {
  position: relative;
  min-height: 100%;
  overflow: hidden;
}

.view-size {
  position: relative;
  max-width: 1852px;
  padding: 0 16px;
  margin: 0 auto;
}

.view-size:after {
  content: '';
  clear: both;
  display: block;
  height: 0;
}

.view-size--md {
  position: relative;
  max-width: 1452px;
  padding: 0 16px;
  margin: 0 auto;
}

.view-size--md:after {
  content: '';
  clear: both;
  display: block;
  height: 0;
}

.view-size--no-gap {
  max-width: 1280px;
}

.view-section {
  position: relative;
  padding-top: 0;
}

@media only screen and (min-width: 1025px) {
  .view-section {
    padding-top: 78px;
  }
}

.view-header {
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  padding: 6px 0;
  z-index: 25;
  background-color: #fff;
}

@media only screen and (min-width: 1025px) {
  .view-header {
    height: initial;
    padding: 16px 0;
  }
}

.view-header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 46px;
}

.view-footer {
  position: relative;
}

.view-footer__pre-footer-wrapper {
  background-color: #f3f2f0;
  padding: 3.375rem 0;
  background-image: url("../images/line_t1.png");
  background-repeat: no-repeat;
  background-position: 100% 100%;
}

.view-footer__pre-footer {
  display: flex;
  justify-content: space-between;
}

.view-footer__logo {
  margin-bottom: 1.625rem;
  display: none;
}

@media only screen and (min-width: 1025px) {
  .view-footer__logo {
    display: inline-block;
  }
}

.view-footer__phones {
  margin-bottom: 2.25rem;
}

.view-footer__contacts {
  margin-bottom: 2.5rem;
}

.view-footer__menu-block {
  display: none;
}

@media only screen and (min-width: 1025px) {
  .view-footer__menu-block {
    display: block;
  }
}

.view-footer__bottom {
  padding: 2.75rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

@media only screen and (min-width: 1025px) {
  .view-footer__bottom {
    flex-direction: row;
  }
}

.view-footer__main-block {
  width: 100%;
}

@media only screen and (min-width: 1025px) {
  .view-footer__main-block {
    width: 34.4%;
  }
}

.zoom-in {
  /* animate in */
  /* animate out */
  /* Dark overlay, start state */
  /* animate in */
  /* animate out */
}

.zoom-in .hidden-wrapper {
  opacity: 0;
  transition: all 0.3s ease-in-out;
  transform: scale(0.8);
}

.zoom-in.mfp-ready .hidden-wrapper {
  opacity: 1;
  transform: scale(1);
}

.zoom-in.mfp-removing .hidden-wrapper {
  transform: scale(0.8);
  opacity: 0;
}

.zoom-in.mfp-bg {
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

.zoom-in.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}

.hidden-wrapper {
  position: relative;
  margin: 1.2rem auto;
  width: 100%;
}

.hidden-wrapper--lg {
  max-width: 1280px;
}

.hidden-wrapper--md {
  max-width: 980px;
}

.hidden-wrapper--sm {
  max-width: 480px;
}

.hidden-wrapper--strip {
  background: none;
  padding: 0;
}

.hidden-wrapper--no-gap {
  padding: 0;
}

.hidden-wrapper ._show-in--hidden-wrapper {
  display: block;
}

.view-text {
  color: inherit;
  position: relative;
  font-size: 16px;
  line-height: 26px;
  font-family: 'Open Sans';
}

@media only screen and (min-width: 1025px) {
  .view-text {
    font-size: 18px;
    line-height: 30px;
  }
}

.view-text blockquote > :first-child, .view-text > :first-child {
  margin-top: 0;
}

.view-text blockquote > :last-child, .view-text > :last-child {
  margin-bottom: 0;
}

.view-text h1 {
  font-size: 3rem;
  line-height: 100%;
  color: #19937f;
  font-weight: 500 !important;
  font-family: 'RistrettoPro';
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.view-text p {
  font-size: 14px;
  line-height: 24px;
  font-family: 'Open Sans';
  font-weight: 300;
}

.view-text table {
  width: 100%;
  border: none;
}

.view-text table th {
  height: 66px;
  padding: 0 24px;
  background-color: #b89b5e;
  color: #fff;
  font-size: 1.875rem;
  font-weight: 500;
  font-family: 'RistrettoPro';
}

.view-text table th span {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.view-text table tr {
  border: none;
  padding-bottom: 4px;
}

.view-text table tr td:first-of-type span {
  padding-left: 0;
  position: relative;
}

@media only screen and (min-width: 640px) {
  .view-text table tr td:first-of-type span {
    padding-left: 44px;
  }
}

.view-text table tr td:first-of-type span:after {
  position: absolute;
  content: "";
  left: 5px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  background-color: #7db7a6;
  height: 1px;
  width: 20px;
  display: none;
}

@media only screen and (min-width: 640px) {
  .view-text table tr td:first-of-type span:after {
    display: block;
  }
}

.view-text table tr td:nth-child(2) {
  width: 144px;
}

.view-text table tr td:nth-child(2) span {
  padding-left: 18px;
}

.view-text table tr:hover td span {
  background-color: #e1dbcd;
}

.view-text table td {
  font-size: 16px;
  font-family: 'Open Sans';
  height: 68px;
  border-bottom: 1px solid #e1dbcd;
  border-collapse: collapse;
  padding-left: 0;
  padding-right: 0;
  color: #2d3b4e;
}

.view-text table td span {
  margin-bottom: 4px;
  margin-top: 4px;
  width: 100%;
  display: flex;
  align-items: center;
  height: calc(100% - 8px);
  transition: background-color 0.5s ease;
}

.view-text h1, .view-text h2, .view-text h3, .view-text h4, .view-text h5, .view-text h6 {
  clear: both;
  line-height: 1.25;
  font-weight: bold;
  margin: 1.4em 0 1rem;
}

.view-text h1 {
  font-size: 2.5em;
}

.view-text h2 {
  font-size: 2.25em;
}

.view-text h3 {
  font-size: 1.75em;
}

.view-text h4 {
  font-size: 1.5em;
}

.view-text h5 {
  font-size: 1.25em;
}

.view-text h6 {
  font-size: 1em;
}

.view-text p, .view-text ul, .view-text ol, .view-text hr, .view-text dl, .view-text pre, .view-text address, .view-text blockquote, .view-text table,
.view-text .table-wrapper, .view-text .media-wrapper {
  margin: 1em 0;
}

.view-text blockquote {
  padding: 1em 1.5em;
  background-color: #f7f8e2;
}

.view-text hr {
  clear: both;
  border-width: 0;
  border-top-width: 1px;
}

.view-text ul, .view-text ol {
  padding-left: 1.5em;
}

.view-text dd {
  margin-left: 1.5em;
}

.view-text dt {
  font-weight: bold;
}

.view-text dd + dt {
  margin: 1em 0 0;
}

.view-text pre, .view-text code, .view-text kbd, .view-text samp {
  font-style: normal;
  font-weight: normal;
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
}

.view-text pre {
  tab-size: 4;
  padding: 1em 1.5em;
  background-color: #f5f5f5;
}

.view-text code, .view-text kbd, .view-text samp {
  padding: 0 .4em;
}

.view-text code {
  background-color: #f5f5f5;
}

.view-text kbd {
  background-color: #e1f0fa;
}

.view-text samp {
  background-color: #f7f8e2;
}

.view-text iframe {
  border-width: 0;
}

.view-text audio {
  width: 100%;
}

.view-text audio[controls] {
  display: block;
}

.view-text .media-wrapper__holder {
  background-color: #f5f5f5;
  position: relative;
}

.view-text .media-wrapper__holder iframe, .view-text .media-wrapper__holder video {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.view-text caption {
  margin-bottom: .5em;
  caption-side: top;
}

.view-text .table-null, .view-text .table-null th, .view-text .table-null td {
  border-color: transparent;
}

.view-text .table-null th {
  background: transparent;
}

.view-text .table-zebra, .view-text .table-zebra th, .view-text .table-zebra td {
  border-color: transparent;
}

.view-text .table-zebra th {
  background: transparent;
}

.view-text .table-zebra tr:nth-child(odd) {
  background-color: #f5f5f5;
}

.view-text a {
  color: #43f;
}

.view-text a:visited {
  color: #0e00ad;
}

.view-text a:hover {
  color: #f34;
}

.view-text a:active {
  color: #000;
}

.view-text b, .view-text strong {
  font-weight: bold;
}

.view-text i, .view-text cite, .view-text em, .view-text var, .view-text address, .view-text dfn, .view-text caption {
  font-style: italic;
}

.view-text abbr[title], .view-text dfn[title] {
  text-decoration: none;
  border-bottom: 1px dotted;
  cursor: help;
}

@media only screen and (max-width: 1024px) {
  .view-text > blockquote,
  .view-text > pre,
  .view-text > .media-wrapper,
  .view-text > video,
  .view-text > iframe {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .view-text > blockquote,
  .view-text > pre {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.view-text--article > p,
.view-text--article > h1,
.view-text--article > h2,
.view-text--article > h3,
.view-text--article > h4,
.view-text--article > h5,
.view-text--article > h6,
.view-text--article > hr,
.view-text--article > ul,
.view-text--article > ol,
.view-text--article > dl,
.view-text--article > audio,
.view-text--article > address {
  max-width: 1025px;
  margin-left: auto;
  margin-right: auto;
}

.table-wrapper {
  clear: both;
  display: block;
  position: relative;
  padding: 0;
  margin: 0;
}

.table-wrapper__holder {
  max-width: 100%;
  overflow-x: auto;
  padding: 0;
  margin: 0;
}

.table-wrapper__table {
  width: 100%;
  border-collapse: collapse;
}

.table-wrapper:before, .table-wrapper:after {
  content: '';
  width: 8px;
  position: absolute;
  top: 0;
  bottom: 0;
  pointer-events: none;
  opacity: 0;
  transition: opacity .25s linear;
}

.table-wrapper:before {
  left: 0;
  background: -moz-linear-gradient(left, rgba(0, 0, 0, 0.8) 0%, transparent 100%);
  background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.8) 0%, transparent 100%);
  background: linear-gradient(to right, rgba(0, 0, 0, 0.8) 0%, transparent 100%);
}

.table-wrapper:after {
  right: 0;
  background: -moz-linear-gradient(left, transparent 0%, rgba(0, 0, 0, 0.8) 100%);
  background: -webkit-linear-gradient(left, transparent 0%, rgba(0, 0, 0, 0.8) 100%);
  background: linear-gradient(to right, transparent 0%, rgba(0, 0, 0, 0.8) 100%);
}

.table-wrapper--outside-left:before {
  opacity: .3;
}

.table-wrapper--outside-right:after {
  opacity: .3;
}

.content-image {
  position: relative;
}

.content-image--width-1200-and-more {
  display: block;
  float: none !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.view-text--article .content-image--width-600-and-more {
  display: block;
  float: none !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

@media only screen and (max-width: 1439px) {
  .content-image--width-1000-and-more {
    display: block;
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media only screen and (max-width: 1365px) {
  .content-image--width-900-and-more {
    display: block;
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media only screen and (max-width: 1279px) {
  .content-image--width-800-and-more {
    display: block;
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media only screen and (max-width: 767px) {
  .content-image--width-500-and-more {
    display: block;
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media only screen and (max-width: 639px) {
  .content-image--width-300-and-more {
    display: block;
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media only screen and (max-width: 479px) {
  .content-image--width-200-and-more {
    display: block;
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media only screen and (max-width: 374px) {
  .content-image--width-100-and-more {
    display: block;
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.breadcrumbs {
  width: 100%;
  display: table;
  font-size: 0;
  line-height: 0;
  margin: 1rem 0;
  font-size: 1rem;
}

.breadcrumbs a {
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
}

.breadcrumbs a:hover {
  color: #b89b5e;
}

.breadcrumbs span {
  color: #19937f;
  display: inline-block;
  vertical-align: top;
  font-size: 1em;
  line-height: 1.4;
  margin-right: .5em;
  text-transform: uppercase;
}

.breadcrumbs span:before {
  margin-right: .5em;
  content: '/';
  display: inline-block;
  vertical-align: top;
  color: #fff;
}

.breadcrumbs span:first-child:before {
  display: none;
}

@media only screen and (max-width: 767px) {
  .breadcrumbs {
    display: block;
  }
  .breadcrumbs span, .breadcrumbs span:before {
    display: none;
  }
  .breadcrumbs span:nth-last-child(2) {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    background: none;
    margin: 0;
  }
  .breadcrumbs span:nth-last-child(2):before {
    content: '<';
    margin: 0 5px 0 3px;
    display: inline-block;
  }
}

.sitemap {
  max-width: 768px;
  padding: 1rem;
  background-color: #f6f7f9;
  position: relative;
  font-size: 1rem;
  margin-bottom: 1.5rem;
}

@media only screen and (min-width: 768px) {
  .sitemap {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.sitemap ul {
  list-style: none;
  padding: 0 0 0 2em;
  margin: 0;
}

.sitemap li {
  list-style: none;
  line-height: 1.4em;
}

.sitemap a {
  padding: .2em 1em .2em .5em;
  display: block;
  color: #00e;
}

.sitemap a:visited {
  color: #0e00ad;
}

.sitemap a:hover {
  color: #f34;
  background-color: rgba(0, 0, 0, 0.035);
}

.sitemap a:active {
  color: #000;
  background-color: rgba(0, 0, 0, 0.035);
}

.sitemap > ul {
  padding: 0;
  margin: 1em 0;
  position: relative;
  overflow: hidden;
}

.sitemap > ul > li > ul {
  padding-left: 0;
}

.sitemap > ul ul {
  list-style: none;
  padding: 0 0 0 2em;
  margin: 0;
}

.sitemap > ul ul li {
  position: relative;
  margin: .1em 0;
}

.sitemap > ul ul li:hover {
  background-color: rgba(0, 0, 0, 0.035);
}

.sitemap > ul ul li a {
  position: relative;
  padding-left: 2.5em;
}

.sitemap > ul ul li a:before, .sitemap > ul ul li a:after {
  content: '';
  position: absolute;
  top: .2em;
  width: 1600px;
  height: 1.4em;
  text-align: right;
  opacity: .3;
  background: transparent repeat-x center right;
}

.sitemap > ul ul li a:before {
  left: 0;
  width: 2em;
  background-image: url(pic/sitemap-start.svg);
}

.sitemap > ul ul li a:after {
  right: 100%;
  background-image: url(pic/sitemap-middle.svg);
}

.sitemap > ul ul li:last-child > a:only-child:before {
  background-image: url(pic/sitemap-end.svg);
}

.title {
  font-family: 'RistrettoPro';
  color: inherit;
}

.title--fz48-fw500 {
  font-size: 2.6rem;
  font-weight: 500;
}

@media only screen and (min-width: 1025px) {
  .title--fz48-fw500 {
    font-size: 3rem;
  }
}

.text {
  font-family: 'RistrettoPro';
  color: inherit;
}

.text--fz28-fw600 {
  font-size: 1.75rem;
  font-weight: 600;
}

.text--fz36-fwb {
  font-size: 2.25rem;
  font-weight: bold;
}

.text--fz36-fw500 {
  font-size: 2.25rem;
  font-weight: 500;
}

.text--fz28-fw500 {
  font-size: 1.75rem;
  font-weight: 500;
}

.text--fz30-fw500 {
  font-size: 1.875rem;
  font-weight: 500;
}

.text--fz24-fw500 {
  font-size: 24px;
  font-weight: 500;
}

.text--fz18 {
  font-size: 16px;
}

@media only screen and (min-width: 1025px) {
  .text--fz18 {
    font-size: 18px;
  }
}

.text2 {
  font-family: 'Open Sans';
  color: inherit;
}

.text2--fz32 {
  font-size: 2rem;
}

.text2--fz24-fw300 {
  font-size: 1.5rem;
  font-weight: 300;
}

.text2--fz18-fw300 {
  font-size: 1.125rem;
  font-weight: 300;
}

.text2--fz24 {
  font-size: 1.5rem;
}

.text2--fz18 {
  font-size: 16px;
}

@media only screen and (min-width: 1025px) {
  .text2--fz18 {
    font-size: 18px;
  }
}

.text2--fz16 {
  font-size: 16px;
}

.text2--fz12 {
  font-size: 12px;
}

.text2--fz12-fw300 {
  font-size: 12px;
  font-weight: 300;
}

.more-link {
  padding-right: 4.375rem;
  position: relative;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  height: 26px;
}

.more-link__image-holder {
  width: 46px;
  height: 26px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto 0;
  overflow: hidden;
}

.more-link__image-holder:before {
  top: 0;
  bottom: 0;
  margin: auto 0;
  right: 14px;
  height: 1px;
  content: "";
  position: absolute;
  width: 32px;
  background-color: #b89b5e;
  z-index: -1;
}

.more-link__text {
  text-transform: uppercase;
  color: #b89b5e;
  letter-spacing: 1px;
}

.more-link__arrow, .more-link__square {
  position: absolute;
  top: 0;
  fill: #b89b5e;
}

.more-link__square {
  width: 26px;
  height: 26px;
  opacity: 0.3;
  right: 0;
}

.more-link__arrow {
  width: 10px;
  height: 18px;
  right: 6px;
  bottom: 0;
  margin: auto;
}

.more-link--law {
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: calc(100% - 20px);
  display: flex;
  justify-content: center;
  padding-right: 0;
  opacity: 0;
  transition: opacity 0.5s ease;
  z-index: 15;
}

.more-link--law .more-link__text {
  color: #fff;
}

.more-link--law .more-link__arrow {
  fill: #fff;
}

.more-link--law .more-link__image-holder:before {
  background-color: #fff;
}

.more-link:hover .more-link__image-holder:before {
  animation: lines_hover_animation 0.5s alternate;
}

.more-link:hover .more-link__image-holder .more-link__arrow {
  animation: blink 0.8s alternate;
}

.btn {
  display: inline-block;
  background-color: transparent;
  border: 2px solid #fff;
  height: 50px;
  line-height: 50px;
  background-color: transition;
  outline: none;
  color: #fff;
  position: relative;
  z-index: 1;
  text-decoration: none;
  backface-visibility: hidden;
  transition: border-color 0.6s ease;
}

.btn:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #19937f;
  z-index: 1;
  transform: rotateY(90deg);
  opacity: 0;
  transition: opacity 0.6s ease-in-out, transform 0s 0.6s ease, background-color 0.3s ease;
}

.btn__hover-effect {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.btn__hover-effect:before, .btn__hover-effect:after {
  position: absolute;
  content: "";
  background-color: #fff;
  top: 50%;
  margin-top: -0.5px;
  width: 44px;
  height: 1px;
  transition: transform .7s, opacity 0.3s ease-in-out;
}

.btn__hover-effect:before {
  left: -22px;
}

.btn__hover-effect:after {
  right: -22px;
}

.btn__content {
  font-size: 22px;
  font-weight: 500;
  text-transform: uppercase;
  font-family: 'RistrettoPro';
  letter-spacing: 1px;
  position: relative;
  z-index: 2;
  text-align: center;
}

@media only screen and (min-width: 768px) {
  .btn__content {
    font-size: 24px;
  }
}

.btn--disabled {
  pointer-events: none;
}

.btn--big {
  padding: 0 10px;
  width: 100%;
  max-width: 388px;
}

@media only screen and (min-width: 768px) {
  .btn--big {
    padding: 0 42px;
  }
}

.btn--w100 {
  width: 100%;
}

.btn:hover {
  border-color: #19937f;
}

.btn:hover:before {
  transform: rotateY(0deg);
  transition: transform 0.3s ease-in-out, opacity 0s ease, background-color 0.3s ease;
  opacity: 1;
}

.btn:hover .btn__hover-effect:before {
  transform: translate(-22px);
  opacity: 0;
  transition: transform .5s, opacity 0.3s ease-in-out;
}

.btn:hover .btn__hover-effect:after {
  transform: translate(22px);
  opacity: 0;
  transition: transform .5s, opacity 0.3s ease-in-out;
}

.btn:active {
  border-color: #137061;
  transition: all 0s;
}

.btn:active:before {
  background-color: #137061;
  transition: all 0s;
}

.page404 {
  position: relative;
}

.page404__content {
  position: absolute;
  left: 0;
  top: 30px;
  width: 100%;
  z-index: 2;
}

.page404 .main-slider-wrapper__bg {
  width: 956px;
  height: 576px;
  z-index: 1;
}

.page404__error-num {
  text-transform: uppercase;
  color: #fff;
  margin-top: 3.125rem;
  margin-bottom: 2.125rem;
}

.page404__error-title {
  color: #b89b5e;
  margin-bottom: 10px;
}

.page404__error-text {
  color: #fff;
  width: 302px;
  line-height: 30px;
}

.page404__bottom {
  width: 100%;
  position: absolute;
  bottom: 80px;
  left: 0;
}

.page404__back-link {
  color: #fff;
  text-decoration: none;
  padding-left: 4.375rem;
  position: relative;
}

.page404__back-link:hover {
  color: #b89b5e;
}

.page404__back-link:hover .more-link__arrow,
.page404__back-link:hover .more-link__square {
  fill: #b89b5e;
}

.page404__back-link:hover .more-link__image-holder:before {
  background-color: #b89b5e;
}

.page404__back-link-wrapper:first-of-type {
  margin-bottom: 2.75rem;
}

.page404__back-link-wrapper .more-link__image-holder {
  opacity: 1;
  left: 0;
  right: initial;
}

.page404__back-link-wrapper .more-link__arrow,
.page404__back-link-wrapper .more-link__square {
  fill: #fff;
  transition: fill 0.5s ease;
}

.page404__back-link-wrapper .more-link__image-holder:before {
  background-color: #fff;
  transition: background-color 0.5s ease;
  z-index: 1;
}

.pagination {
  text-align: center;
  display: flex;
  justify-content: center;
}

.pagination__btn {
  text-decoration: none;
  color: #dbcdae;
  border: 2px solid transparent;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin: 0 5px;
  transition: color 0.4s ease;
}

@media only screen and (min-width: 768px) {
  .pagination__btn {
    margin: 0 10px;
    width: 50px;
    height: 50px;
  }
}

.pagination__btn.is-active {
  border-color: #eae1ce;
  cursor: default;
  user-select: none;
  color: #b89b5e;
}

.pagination__btn:hover {
  color: #b89b5e;
}

.pagination__arrow-wrapper {
  position: relative;
  bottom: 0rem;
  z-index: 55;
  cursor: pointer;
  width: 86px;
  display: none;
}

@media only screen and (min-width: 768px) {
  .pagination__arrow-wrapper {
    display: block;
  }
}

.pagination__arrow-wrapper--left {
  transform: rotate(180deg);
  margin-right: 6.875rem;
}

.pagination__arrow-wrapper--right {
  margin-left: 6.875rem;
}

.pagination__arrow-wrapper:hover .pagination__image-holder:before {
  animation: lines_hover_animation 0.5s alternate;
}

.pagination__arrow-wrapper:hover .pagination__image-holder .pagination__arrow {
  animation: blink 0.8s alternate;
}

.pagination__arrow-wrapper--disabled {
  cursor: default;
  pointer-events: none;
}

.pagination__arrow-wrapper--disabled .pagination__arrow,
.pagination__arrow-wrapper--disabled .pagination__image-holder:before {
  opacity: 0.6;
}

.pagination__image-holder {
  width: 86px;
  height: 52px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto 0;
  overflow: hidden;
}

.pagination__image-holder:before {
  top: 0;
  bottom: 0;
  margin: auto 0;
  right: 26px;
  height: 1px;
  content: "";
  position: absolute;
  width: 64px;
  background-color: #b89b5e;
  z-index: -1;
}

.pagination__arrow, .pagination__square {
  position: absolute;
  top: 0;
  fill: #b89b5e;
}

.pagination__square {
  width: 52px;
  height: 52px;
  opacity: 0.3;
  right: 0;
}

.pagination__arrow {
  width: 18px;
  height: 34px;
  right: 16px;
  bottom: 0;
  margin: auto;
}

.form {
  position: relative;
  font-size: 14px;
}

.form__group {
  display: block;
  position: relative;
  margin: 0 0 1.6rem;
}

.form__group .form__group {
  margin: 0 0 .8rem;
}

.form__label {
  cursor: default;
  display: inline-block;
  vertical-align: top;
  user-select: none;
  margin-bottom: .4rem;
}

.form__caption {
  font-size: .8em;
  margin-bottom: .4em;
}

.form__info {
  display: none;
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  font-size: .7em;
  background-color: #000;
  color: #fff;
  padding: .1em .3em;
}

input:focus ~ .form__info,
textarea:focus ~ .form__info,
select:focus ~ .form__info {
  display: block;
}

label.form__label {
  cursor: pointer;
}

.control-holder {
  position: relative;
}

.control-holder label.has-error {
  vertical-align: top;
  user-select: none;
  font-size: .75em;
  line-height: 1em;
  padding: .1em .5em;
  background-color: #000;
  display: none !important;
  color: #fff;
}

.control-holder--text input, .control-holder--text textarea, .control-holder--text select {
  display: block;
  width: 100%;
}

.control-holder--text input, .control-holder--text select {
  height: 50px;
  line-height: 50px;
  border-radius: 0;
  outline: none;
  padding: 0 14px;
  background-color: #f3f2f1;
  transition: background-color 0.5s ease, border-color 0.4s ease;
  border: 1px solid #f3f2f1;
  font-family: "Open Sans", Arial;
}

.control-holder--text input:focus, .control-holder--text select:focus {
  background-color: #fff;
}

.control-holder--text input.has-error, .control-holder--text select.has-error {
  border-bottom: 1px solid red;
}

.control-holder--text textarea {
  border-radius: 0;
  outline: none;
  height: 120px;
  padding: 14px;
  background-color: #f3f2f1;
  transition: background-color 0.5s ease, border-color 0.4s ease;
  border: 1px solid #f3f2f1;
  font-family: "Open Sans", Arial;
}

.control-holder--text textarea:focus {
  background-color: #fff;
}

.control-holder--flag label {
  user-select: none;
  margin-right: 1em;
}

.control-holder--flag label:only-child, .control-holder--flag label.has-error {
  cursor: default;
  margin-right: 0;
}

.control-holder--flag ins {
  position: relative;
  display: inline-block;
  vertical-align: text-bottom;
  width: 1.2em;
  height: 1.2em;
  background-color: #fff;
  border: 1px solid;
}

.control-holder--flag input {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
}

.control-holder--flag input[type="radio"] ~ ins {
  border-radius: 50%;
}

.control-holder--flag input:checked ~ ins {
  background-color: #000;
  box-shadow: inset 0 0 0 3px #fff;
}

.control-holder--flag input:disabled ~ ins,
.control-holder--flag input:disabled ~ span {
  opacity: .5;
  cursor: not-allowed;
}

.control-holder--file label {
  position: relative;
  display: inline-block;
  vertical-align: top;
}

.control-holder--file input {
  position: absolute;
  outline: none;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  opacity: 0;
}

.control-holder--file ._ellipsis {
  max-width: 200px;
  width: auto;
  display: inline-block;
  vertical-align: top;
}

.search-form {
  display: flex;
  position: fixed;
  top: 58px;
  left: 0;
  transform: translateX(200%);
  width: 100%;
  transition: transform 1s ease;
}

@media only screen and (min-width: 1025px) {
  .search-form {
    position: relative;
    transform: translateY(0%);
    width: initial;
    top: 0;
  }
}

.search-form.is-active {
  transform: translateY(0%);
}

.search-form__content-holder {
  width: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 2px;
  position: relative;
  z-index: 2;
}

.search-form__btn {
  border: 0;
  outline: none;
  background-color: transparent;
  padding: 0;
  text-transform: uppercase;
  background-color: #19937f;
  color: #fff;
  height: 40px;
  position: relative;
  line-height: 40px;
  position: absolute;
  right: 0;
  top: 0;
}

@media only screen and (min-width: 1025px) {
  .search-form__btn {
    position: relative;
  }
}

.search-form__btn:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #b89b5e;
  z-index: 1;
  transform: rotateY(90deg);
  opacity: 0;
  transition: opacity 0.6s ease-in-out, transform 0s 0.6s ease, background-color 0.3s ease;
}

.search-form__btn:hover:before {
  transform: rotateY(0deg);
  transition: transform 0.3s ease-in-out, opacity 0s ease, background-color 0.3s ease;
  opacity: 1;
}

.search-form__btn:active:before {
  transform: rotateY(0deg);
  opacity: 1;
  background-color: #9e8145;
  transition: all 0s ease;
}

.search-form .form__group {
  margin-bottom: 0;
  width: 100%;
}

@media only screen and (min-width: 1025px) {
  .search-form .form__group {
    width: 180px;
  }
}

.search-form input {
  padding-right: 30px;
  height: 40px;
  line-height: 40px;
  border-right: none;
}

@keyframes lines_hover_animation {
  0% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(100%);
  }
  51% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  51% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

.abus-item__title:after {
  transform: rotateY(-90deg);
  transform-origin: left;
  transition: transform 2s ease;
}

.law-item {
  transform: rotateX(-90deg);
  transform-origin: top;
}

.work-scheme__item {
  transform-origin: left;
  transform: scaleX(0);
  transition: transform 1.2s ease;
}

.advantages-item__image:after {
  transform: scaleX(0.16);
  transition: transform 1.2s ease;
  transform-origin: left;
}

.js-inviewed.abus-item__title:after {
  transform: rotateY(0);
}

.js-inviewed.law-item {
  transform: rotateX(0);
}

.js-inviewed.work-scheme__item {
  transform: scaleX(1);
}

.js-inviewed.advantages-item__image:after {
  transform: scaleX(1);
}

._color-black {
  color: #000;
}

._color-gray90 {
  color: #1a1a1a;
}

._color-gray75 {
  color: #404040;
}

._color-gray50 {
  color: #807f7f;
}

._color-gray25 {
  color: #bfbfbf;
}

._color-gray10 {
  color: #e6e6e6;
}

._color-white {
  color: #fff;
}

._color-link {
  color: #43f;
}

._color-primary {
  color: #8074ff;
}

._color-secondary {
  color: #e652a6;
}

._color-success {
  color: #c1af7a;
}

._color-info {
  color: #00b3fe;
}

._color-warning {
  color: #c89600;
}

._color-danger {
  color: #ff6262;
}

._bgcolor-black {
  background-color: #000;
}

._bgcolor-gray90 {
  background-color: #1a1a1a;
}

._bgcolor-gray75 {
  background-color: #404040;
}

._bgcolor-gray50 {
  background-color: #807f7f;
}

._bgcolor-gray25 {
  background-color: #bfbfbf;
}

._bgcolor-gray10 {
  background-color: #e6e6e6;
}

._bgcolor-white {
  background-color: #fff;
}

._bgcolor-link {
  background-color: #43f;
}

._bgcolor-primary {
  background-color: #8074ff;
}

._bgcolor-secondary {
  background-color: #e652a6;
}

._bgcolor-success {
  background-color: #c1af7a;
}

._bgcolor-info {
  background-color: #00b3fe;
}

._bgcolor-warning {
  background-color: #c89600;
}

._bgcolor-danger {
  background-color: #ff6262;
}

._fill-black {
  fill: #000;
}

._fill-gray90 {
  fill: #1a1a1a;
}

._fill-gray75 {
  fill: #404040;
}

._fill-gray50 {
  fill: #807f7f;
}

._fill-gray25 {
  fill: #bfbfbf;
}

._fill-gray10 {
  fill: #e6e6e6;
}

._fill-white {
  fill: #fff;
}

._fill-link {
  fill: #43f;
}

._fill-primary {
  fill: #8074ff;
}

._fill-secondary {
  fill: #e652a6;
}

._fill-success {
  fill: #c1af7a;
}

._fill-info {
  fill: #00b3fe;
}

._fill-warning {
  fill: #c89600;
}

._fill-danger {
  fill: #ff6262;
}

._left {
  float: left;
}

._right {
  float: right;
}

._overflow {
  overflow: hidden;
}

._clear {
  clear: both;
}

._clear-after:after {
  content: '';
  clear: both;
  display: block;
  height: 0;
}

@media only screen and (min-width: 375px) {
  ._xs-left {
    float: left;
  }
  ._xs-right {
    float: right;
  }
  ._xs-overflow {
    overflow: hidden;
  }
}

@media only screen and (min-width: 480px) {
  ._sm-left {
    float: left;
  }
  ._sm-right {
    float: right;
  }
  ._sm-overflow {
    overflow: hidden;
  }
}

@media only screen and (min-width: 640px) {
  ._ms-left {
    float: left;
  }
  ._ms-right {
    float: right;
  }
  ._ms-overflow {
    overflow: hidden;
  }
}

@media only screen and (min-width: 768px) {
  ._md-left {
    float: left;
  }
  ._md-right {
    float: right;
  }
  ._md-overflow {
    overflow: hidden;
  }
}

@media only screen and (min-width: 1025px) {
  ._def-left {
    float: left;
  }
  ._def-right {
    float: right;
  }
  ._def-overflow {
    overflow: hidden;
  }
}

@media only screen and (min-width: 1280px) {
  ._lg-left {
    float: left;
  }
  ._lg-right {
    float: right;
  }
  ._lg-overflow {
    overflow: hidden;
  }
}

._text-left {
  text-align: left;
}

._text-right {
  text-align: right;
}

._text-center {
  text-align: center;
}

._ellipsis {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media only screen and (min-width: 375px) {
  ._xs-text-left {
    text-align: left;
  }
  ._xs-text-right {
    text-align: right;
  }
  ._xs-text-center {
    text-align: center;
  }
}

@media only screen and (min-width: 480px) {
  ._sm-text-left {
    text-align: left;
  }
  ._sm-text-right {
    text-align: right;
  }
  ._sm-text-center {
    text-align: center;
  }
}

@media only screen and (min-width: 640px) {
  ._ms-text-left {
    text-align: left;
  }
  ._ms-text-right {
    text-align: right;
  }
  ._ms-text-center {
    text-align: center;
  }
}

@media only screen and (min-width: 768px) {
  ._md-text-left {
    text-align: left;
  }
  ._md-text-right {
    text-align: right;
  }
  ._md-text-center {
    text-align: center;
  }
}

@media only screen and (min-width: 1025px) {
  ._def-text-left {
    text-align: left;
  }
  ._def-text-right {
    text-align: right;
  }
  ._def-text-center {
    text-align: center;
  }
}

@media only screen and (min-width: 1280px) {
  ._lg-text-left {
    text-align: left;
  }
  ._lg-text-right {
    text-align: right;
  }
  ._lg-text-center {
    text-align: center;
  }
}

._hide {
  display: none;
}

._hide\! {
  display: none !important;
}

@media print {
  ._noprint {
    display: none !important;
  }
}

@media only screen and (min-width: 375px) {
  ._xs-hide {
    display: none;
  }
  ._xs-hide\! {
    display: none !important;
  }
}

@media only screen and (max-width: 374px) {
  ._xs-show {
    display: none;
  }
  ._xs-show\! {
    display: none !important;
  }
}

@media only screen and (min-width: 480px) {
  ._sm-hide {
    display: none;
  }
  ._sm-hide\! {
    display: none !important;
  }
}

@media only screen and (max-width: 479px) {
  ._sm-show {
    display: none;
  }
  ._sm-show\! {
    display: none !important;
  }
}

@media only screen and (min-width: 640px) {
  ._ms-hide {
    display: none;
  }
  ._ms-hide\! {
    display: none !important;
  }
}

@media only screen and (max-width: 639px) {
  ._ms-show {
    display: none;
  }
  ._ms-show\! {
    display: none !important;
  }
}

@media only screen and (min-width: 768px) {
  ._md-hide {
    display: none;
  }
  ._md-hide\! {
    display: none !important;
  }
}

@media only screen and (max-width: 767px) {
  ._md-show {
    display: none;
  }
  ._md-show\! {
    display: none !important;
  }
}

@media only screen and (min-width: 1025px) {
  ._def-hide {
    display: none;
  }
  ._def-hide\! {
    display: none !important;
  }
}

@media only screen and (max-width: 1024px) {
  ._def-show {
    display: none;
  }
  ._def-show\! {
    display: none !important;
  }
}

@media only screen and (min-width: 1280px) {
  ._lg-hide {
    display: none;
  }
  ._lg-hide\! {
    display: none !important;
  }
}

@media only screen and (max-width: 1279px) {
  ._lg-show {
    display: none;
  }
  ._lg-show\! {
    display: none !important;
  }
}

.view-wrapper {
  display: flex;
  flex-direction: column;
}

.view-header {
  order: 1;
}

.view-section {
  order: 2;
}

#seo_text {
  order: 3;
  position: relative;
}

.view-footer {
  order: 4;
}

a {
  transition: color 0.5s ease;
}

.after-header {
  padding: 36px 0 18px 0;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 34%;
  position: relative;
  margin-bottom: 2.875rem;
}

@media only screen and (min-width: 1280px) {
  .after-header {
    background-position: 0 0;
  }
}

.after-header .sub-nav__info-block {
  margin-bottom: 2.25rem;
}

.after-header--feedfix {
  margin-bottom: 0;
}

.after-header:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: linear-gradient(65deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5), transparent, transparent);
}

.after-header:before {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 0;
  border-bottom: 270px solid #b89b5e;
  border-left: 270px solid transparent;
  opacity: 0.6;
  content: "";
  z-index: 1;
  display: none;
}

@media only screen and (min-width: 768px) {
  .after-header:before {
    display: block;
  }
}

.after-header__top {
  position: relative;
  z-index: 2;
}

.feedback-page {
  position: relative;
}

.page-group-quostil {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 2.25rem;
}

.feedbacks-page {
  margin-bottom: 4rem;
}

.feedbacks-grid {
  margin-bottom: 1.875rem;
}

.feedback {
  padding: 10.625rem 0 2.5rem 0;
  min-height: 450px;
  background-repeat: no-repeat;
  background-size: 76%;
  background-position: 50% 0;
  width: 100%;
}

.feedback__content {
  padding: 0 4.375rem;
  width: 100%;
  max-width: 1220px;
  margin: 0 auto;
}

.feedback__feedback {
  padding: 2rem 0 3.625rem 0;
  background-color: #eae1ce;
  background-repeat: no-repeat;
  background-position: 50% -86%;
  background-size: 30%;
  margin-top: 2.375rem;
}

.feedback__feedback-content {
  max-width: 1220px;
  margin: 0 auto;
  width: 100%;
}

.feedback__w-feed {
  background-color: #f3f2f0;
}

.feedback__text {
  line-height: 30px;
}

.feedback__title {
  padding-bottom: 10px;
  margin-bottom: 1.25rem;
  position: relative;
}

.feedback__title:after {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0 auto;
  content: "";
  width: 70px;
  height: 3px;
  background-color: #dbccac;
}

.feedback-form {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 3.125rem 10px 3.75rem 10px;
  overflow: hidden;
}

@media only screen and (min-width: 640px) {
  .feedback-form {
    overflow: visible;
  }
}

.feedback-form__form {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.feedback-form .form__group {
  width: 100%;
}

.feedback-form__group {
  width: 100%;
  display: flex;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 768px) {
  .feedback-form__group {
    flex-direction: row;
  }
}

.feedback-form__group .form__group {
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .feedback-form__group .form__group {
    width: calc(50% - 10px);
  }
}

.feedback-form__title {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
  margin-bottom: 4px;
}

.feedback-form__subtitle {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 4px;
  text-align: center;
  margin-bottom: 1.75rem;
}

.mfp-close:after, .mfp-close:before {
  width: 2px;
  height: 70%;
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  background-color: #b89b5e;
  -webkit-transform-origin: center;
  transform-origin: center;
  content: "";
  -webkit-transition: background-color .3s ease;
  transition: background-color .3s ease;
}

.mfp-close:before {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.mfp-close:after {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.news-page {
  margin-bottom: 5rem;
}

.news-page__grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 3.75rem;
}

.page-subtitle {
  text-transform: uppercase;
  letter-spacing: 4px;
  text-align: center;
  color: #7a8080;
  margin-bottom: 2.625rem;
}

.mfp-close {
  width: 54px;
  height: 54px;
  right: 0;
  top: -54px;
  background-color: #574731 !important;
  color: #b89b5e !important;
  font-size: 0 !important;
  opacity: 1;
}

@media only screen and (min-width: 1280px) {
  .mfp-close {
    right: -54px;
  }
}

.mfp-close:hover:after, .mfp-close:hover:before {
  background-color: #19937f;
}

.mfp-close:active {
  top: -54px;
}

.mails {
  padding: 60px 0;
  background-color: #f3f2f0;
  width: 100%;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  position: relative;
  margin-bottom: 2px;
}

.mails__bg {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  transform: translateY(-10px);
  width: 102px;
  height: 96px;
  fill: #fafaf9;
}

.mails__grid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
}

.mails__item {
  margin: 0 10px;
}

.work-time {
  margin-bottom: 5rem;
}

.work-time__text-block {
  margin-bottom: 2.5rem;
  position: relative;
}

.work-time__text-block b {
  color: #2d3b4e;
}

.work-time__text-block:after {
  position: absolute;
  content: "";
  background-color: #eae1ce;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: -20px;
  height: 3px;
  width: 70px;
}

.addres {
  display: flex;
  margin-bottom: 3.125rem;
  flex-direction: column;
}

@media only screen and (min-width: 1025px) {
  .addres {
    flex-direction: row;
  }
}

.addres__left {
  min-height: 193px;
  border-right: 1px solid #fff;
  width: 100%;
  background-color: #eae1ce;
  padding: 3.625rem 10px;
  position: relative;
  padding-right: 10px;
  order: 2;
}

@media only screen and (min-width: 1025px) {
  .addres__left {
    width: 50%;
    order: 1;
  }
}

@media only screen and (min-width: 1280px) {
  .addres__left {
    padding: 3.625rem;
    padding-right: 230px;
  }
}

@media only screen and (min-width: 1440px) {
  .addres__left {
    padding-right: 3.625rem;
  }
}

.addres__left .law-item__bg {
  bottom: 0;
}

@media only screen and (min-width: 1280px) {
  .addres__left .law-item__bg {
    bottom: 2.375rem;
  }
}

.addres__left .contacts-title,
.addres__left .contacts-text {
  max-width: 374px;
  margin-left: 0;
  margin-right: auto;
}

@media only screen and (min-width: 1025px) {
  .addres__left .contacts-title,
  .addres__left .contacts-text {
    margin-left: auto;
  }
}

.addres__left .more-link {
  position: absolute;
  right: 12px;
  bottom: 12px;
  z-index: 15;
}

@media only screen and (min-width: 1280px) {
  .addres__left .more-link {
    bottom: 3.375rem;
  }
}

.addres__right {
  min-height: 193px;
  border-left: 1px solid #fff;
  width: 100%;
  background-color: #eae1ce;
  background-repeat: no-repeat;
  position: relative;
  background-position: 100% 100%;
  padding: 3.625rem 10px;
  position: relative;
  order: 1;
}

@media only screen and (min-width: 1025px) {
  .addres__right {
    padding: 3.625rem;
    width: 50%;
    order: 2;
  }
}

.contacts-title {
  position: relative;
  z-index: 1;
  margin-bottom: 1rem;
}

.contacts-phones {
  margin-bottom: 3.75rem;
  position: relative;
}

.contacts-phones__grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  z-index: 1;
}

.contacts-phones__bg {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  fill: #f3f2f0;
  transform: translateY(-10px);
  width: 134px;
  height: 128px;
}

.contacts-phones__item {
  padding-right: 20px;
  position: relative;
}

@media only screen and (min-width: 768px) {
  .contacts-phones__item {
    padding-right: 3.75rem;
  }
}

.contacts-phones__item:after {
  position: absolute;
  right: 1.25rem;
  content: "//";
  color: #19937f;
  top: 0;
  font-size: 36px;
  font-family: 'Open Sans';
  font-weight: 300;
  display: none;
}

@media only screen and (min-width: 768px) {
  .contacts-phones__item:after {
    display: block;
  }
}

.contacts-phones__item:last-of-type:after {
  display: none;
}

.contacts-phones__link {
  display: inline-block;
  color: #2d3b4e;
  text-decoration: none;
}

.law-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.advantages {
  margin-bottom: 0.375rem;
}

.advantages__grid {
  padding-top: 1.5rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

@media only screen and (min-width: 1440px) {
  .advantages__grid {
    justify-content: space-between;
  }
}

.advantages-item {
  padding-left: 88px;
  width: 422px;
  padding-right: 0;
  position: relative;
  margin-bottom: 2.5rem;
}

@media only screen and (min-width: 768px) {
  .advantages-item {
    padding-right: 16px;
  }
}

.advantages-item__title {
  padding-bottom: 14px;
  margin-bottom: 16px;
  position: relative;
  line-height: 100%;
}

.advantages-item__text {
  font-weight: 300;
}

.advantages-item__image {
  position: absolute;
  left: 0;
  top: 60px;
}

.advantages-item__image svg {
  position: absolute;
}

.advantages-item__image svg:nth-child(2) {
  width: 54px;
  height: 55px;
  fill: #b89b5e;
  left: 0;
  top: 0;
}

.advantages-item__image svg:nth-child(1) {
  width: 105px;
  height: 105px;
  fill: #f3f2f0;
  z-index: -1;
  left: -4px;
  top: -17px;
  backface-visibility: hidden;
}

.advantages-item__image:after {
  position: absolute;
  left: 26px;
  bottom: -15px;
  transfrom-origin: left;
  content: "";
  width: 120px;
  height: 2px;
  background-color: #b89b5e;
}

.footer-nav {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.footer-nav__title {
  color: #2d3b4e;
  text-transform: uppercase;
  margin-bottom: 2.75rem;
  padding-bottom: 2.75rem;
  position: relative;
}

.footer-nav__title:after {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 34px;
  background-color: #b89b5e;
  content: "";
}

.footer-nav__item {
  margin-bottom: 6px;
}

.footer-nav__link {
  font-size: 14px;
  font-family: 'Open Sans';
  color: #7a8080;
  text-decoration: none;
  padding: 6px 0;
  display: inline-block;
  transition: color 0.5s ease;
  position: relative;
}

.footer-nav__link:after {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  transition: transform 0.5s ease;
  background-color: #2d3b4e;
  content: "";
  transform: scaleX(0);
  transform-origin: left;
}

.footer-nav__link:hover {
  color: #2d3b4e;
}

.footer-nav__link:hover:after {
  transform: scaleX(1);
}

.socs-item {
  width: 50px;
  height: 50px;
  margin: 0 10px;
  position: relative;
  border-radius: 100%;
}

.socs-item svg {
  fill: #fff;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.socs-item--fb {
  background-color: #3262a4;
}

.socs-item--fb svg {
  width: 12px;
  height: 22px;
}

.socs-item--vk {
  background-color: #4da1bc;
}

.socs-item--vk svg {
  width: 22px;
  height: 12px;
}

.socs-item--inst {
  background-color: #d6226a;
}

.socs-item--inst svg {
  width: 24px;
  height: 24px;
}

#seo_text {
  margin-bottom: 2.5rem;
}

.socs-block {
  display: flex;
  margin: 16px 0;
}

@media only screen and (min-width: 1025px) {
  .socs-block {
    margin: 0;
  }
}

.copy {
  color: #7a8080;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
}

@media only screen and (min-width: 1025px) {
  .copy {
    text-align: left;
  }
}

.wezom {
  text-align: center;
}

@media only screen and (min-width: 1025px) {
  .wezom {
    text-align: left;
  }
}

.wezom__link {
  color: #7a8080;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-decoration: none;
}

.wezom__link:hover {
  color: red;
}

.wezom__link:hover svg {
  fill: red;
}

.wezom svg {
  transition: fill 0.3s ease;
  width: 30px;
  height: 10px;
  fill: #343434;
}

.law-item {
  width: 320px;
  height: 166px;
  margin: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  border: 1px solid #dfdeda;
  text-decoration: none;
  position: relative;
  transition: border-color 0.3s 0.2s ease, transform 1.2s ease;
  backface-visibility: hidden;
}

.law-item__text {
  text-align: center;
  line-height: 100%;
  color: #2d3b4e;
  max-width: 220px;
  transition: transform 0.5s ease, color 0.4s ease;
}

.law-item--services {
  max-width: 200px;
  height: 144px;
}

@media only screen and (min-width: 1440px) {
  .law-item--services {
    max-width: 240px;
  }
}

.law-item__bg {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 60px;
  width: 180px;
  fill: #f3f2f0;
  transition: fill 0.5s ease;
  z-index: -1;
}

@media only screen and (min-width: 768px) {
  .law-item__bg {
    z-index: 1;
  }
}

.law-item:before {
  background-color: #dfdeda;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 1px;
  height: 40px;
  content: "";
  position: absolute;
  transition: transform 0.5s ease;
}

.law-item:after {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  content: "";
  transform: scale(1);
  opacity: 0;
  background-color: #b89b5e;
  transition: opacity 0.5s ease, transform 0.5s ease;
  z-index: -1;
}

.law-item:hover {
  border-color: transparent;
  transition: border-color 0s 0s ease;
}

.law-item:hover:after {
  opacity: 1;
  transform: scaleX(1.08) scaleY(1.14);
}

.law-item:hover:before {
  transform: translateY(-12px);
}

.law-item:hover .law-item__text {
  color: #fff;
  transform: translateY(-12px);
}

.law-item:hover .law-item__bg {
  fill: #9c885b;
}

.law-item:hover .more-link--law {
  opacity: 1;
}

.feed-form {
  display: flex;
}

.feed-form .form__group {
  margin-bottom: 0;
  width: 234px;
}

.feed-form input {
  padding-right: 30px;
  height: 40px;
  border-right: none;
  background-color: #fff;
}

.feed-form__content-holder {
  width: 122px;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 2px;
  position: relative;
  z-index: 2;
}

@media only screen and (min-width: 640px) {
  .feed-form__content-holder {
    width: 190px;
  }
}

.feed-form__btn {
  border: 0;
  outline: none;
  background-color: transparent;
  padding: 0;
  text-transform: uppercase;
  background-color: #19937f;
  color: #fff;
  height: 40px;
  position: relative;
  line-height: 40px;
}

.feed-form__btn:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #b89b5e;
  z-index: 1;
  transform: rotateY(90deg);
  opacity: 0;
  transition: opacity 0.6s ease-in-out, transform 0s 0.6s ease, background-color 0.3s ease;
}

.feed-form__btn:hover:before {
  transform: rotateY(0deg);
  transition: transform 0.3s ease-in-out, opacity 0s ease, background-color 0.3s ease;
  opacity: 1;
}

.feed-form__btn:active:before {
  transform: rotateY(0deg);
  opacity: 1;
  background-color: #9e8145;
  transition: all 0s ease;
}

.block-title-wrapper {
  text-align: center;
  margin-bottom: 16px;
}

.block-title {
  text-transform: uppercase;
  color: #19937f;
  letter-spacing: 1px;
  margin-bottom: 4px;
  display: inline-block;
  line-height: 100%;
  text-decoration: none;
}

.index-news {
  margin-bottom: 3.75rem;
}

.index-news__grid {
  display: flex;
  width: 100%;
  position: relative;
  left: 0;
  padding-top: 1.875rem;
}

@media only screen and (min-width: 1025px) {
  .index-news__grid {
    width: 2060px;
  }
}

.news-item {
  width: 320px;
  border: 1px solid #dfdeda;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  position: relative;
  transition: border-color 0.3s 0.2s ease;
  margin: 10px;
}

@media only screen and (min-width: 1025px) {
  .news-item {
    width: 360px;
    margin: 1.25rem 26px;
  }
}

.news-item__descr {
  position: relative;
  padding: 2.125rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
}

.news-item__date {
  text-align: center;
  margin-bottom: 1rem;
  color: #7a8080;
}

.news-item__text {
  text-align: center;
  color: #2d3b4e;
  height: 60px;
  overflow: hidden;
  line-height: 100%;
  margin-bottom: 2.5rem;
}

.news-item .more-link__text {
  color: #b89b5e;
}

.news-item .more-link--law .more-link__image-holder:before {
  background-color: #b89b5e;
}

.news-item .more-link--law .more-link__arrow {
  fill: #b89b5e;
}

.news-item:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: -1;
  left: 0;
  top: 0;
  box-shadow: 0px 9px 45px 2px rgba(128, 128, 128, 0.76);
  opacity: 0;
  transition: opacity 0.5s ease, transform 0.5s ease;
  display: none;
}

@media only screen and (min-width: 1025px) {
  .news-item:after {
    display: block;
  }
}

.news-item:hover {
  border-color: transparent;
  transition: border-color 0.2s 0s ease;
}

.news-item:hover:after {
  opacity: 1;
  transform: scaleX(1.05) scaleY(1.05);
}

.news-item:hover .more-link--law {
  opacity: 1;
}

.news-item:hover .law-item__bg {
  fill: #f1ebdf;
}

.abus-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media only screen and (min-width: 1025px) {
  .abus-items {
    flex-wrap: nowrap;
  }
}

.abus-index {
  margin-bottom: 1.25rem;
}

@media only screen and (min-width: 1025px) {
  .abus-index {
    margin-bottom: 3.75rem;
  }
}

.abus-content {
  display: flex;
  padding: 1.25rem 0;
  flex-wrap: wrap;
}

@media only screen and (min-width: 1366px) {
  .abus-content {
    flex-wrap: nowrap;
  }
}

.abus-item {
  display: flex;
  flex-direction: column;
  padding: 0 8px;
  max-width: 360px;
  min-width: 250px;
  margin-bottom: 2.5rem;
}

@media only screen and (min-width: 1025px) {
  .abus-item {
    margin-bottom: 0;
  }
}

.abus-item__title {
  color: #2d3b4e;
  max-width: 130px;
  line-height: 24px;
  margin-bottom: 18px;
  padding-bottom: 18px;
  position: relative;
}

.abus-item__title:before {
  width: 40px;
  height: 40px;
  background-color: #f8f6f2;
  bottom: 10px;
  z-index: -1;
  left: 0;
  content: "";
  position: absolute;
}

.abus-item__title:after {
  width: 60px;
  height: 2px;
  background-color: #b89b5e;
  bottom: 0;
  left: -20px;
  content: "";
  position: absolute;
}

.abus-item__text {
  color: #343434;
  font-weight: 300;
}

.hamburger {
  display: block;
}

@media only screen and (min-width: 1025px) {
  .hamburger {
    display: none;
  }
}

.hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
  background-color: #2d3b4e;
}

.page-title {
  color: #19937f;
  text-align: center;
  text-transform: uppercase;
}

.abus-int {
  max-width: initial;
  margin-right: 0;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}

@media only screen and (min-width: 1366px) {
  .abus-int {
    margin-bottom: 0;
    margin-right: 4%;
    max-width: 280px;
    text-align: left;
  }
}

.abus-int__title {
  color: #2d3b4e;
  line-height: 100%;
  margin-bottom: 10px;
}

@media only screen and (min-width: 1366px) {
  .abus-int__title {
    margin-bottom: 3.125rem;
  }
}

.search-resp,
.sort-resp {
  cursor: pointer;
  display: block;
  position: relative;
  margin: 0 8px;
}

.search-resp svg,
.sort-resp svg {
  width: 32px;
  height: 32px;
  fill: #2d3b4e;
}

.resp-group {
  position: absolute;
  right: 2rem;
  top: 6px;
  display: flex;
}

@media only screen and (min-width: 1025px) {
  .resp-group {
    display: none;
  }
}

.block-subtitle {
  color: #7a8080;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.lng-block {
  display: none;
}

@media only screen and (min-width: 1025px) {
  .lng-block {
    display: flex;
  }
}

.logo-resp {
  display: flex;
  transform: translateX(-20px);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  margin: 0 auto;
  width: 202px;
  z-index: 5;
  align-items: center;
  justify-content: center;
}

@media only screen and (min-width: 640px) {
  .logo-resp {
    transform: translateX(0);
  }
}

@media only screen and (min-width: 1025px) {
  .logo-resp {
    display: none;
  }
}

@media only screen and (min-width: 640px) {
  .logo-resp {
    top: -4px;
  }
}

.logo-resp img {
  width: auto;
  height: 58px;
}

@media only screen and (min-width: 640px) {
  .logo-resp img {
    height: 66px;
  }
}

.lng-item {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin: 0 10px;
  position: relative;
}

.lng-item:first-of-type {
  margin-left: 0;
}

.lng-item:last-of-type {
  margin-right: 0;
}

.lng-item__link {
  display: flex;
  justify-content: center;
  letter-spacing: 3px;
  align-items: center;
  padding: 10px 6px;
  width: 36px;
  text-decoration: none;
  text-transform: uppercase;
  color: #b89b5e;
  position: relative;
  z-index: 3;
}

.lng-item__link:hover {
  color: #19937f;
}

.lng-item.is-active {
  user-select: none;
  pointer-events: none;
}

.lng-item.is-active .lng-item__link {
  color: #fff;
}

.lng-item.is-active .lng-item__link:after {
  position: absolute;
  content: "";
  left: 0;
  top: 100%;
  height: 18px;
  width: 100%;
  border-radius: 0 0 0 0;
  background-color: #b89b5e;
  display: none;
}

@media only screen and (min-width: 1025px) {
  .lng-item.is-active .lng-item__link:after {
    display: block;
  }
}

.lng-item.is-active .lng-item__link:before {
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 18px;
  background-color: #fff;
  height: 1px;
  content: "";
  z-index: 2;
  display: none;
}

@media only screen and (min-width: 1025px) {
  .lng-item.is-active .lng-item__link:before {
    display: block;
  }
}

.lng-item.is-active:before {
  background-color: #b89b5e;
  position: absolute;
  content: "";
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.lng-item.is-active:after {
  width: 0;
  height: 0;
  border-top: 30px solid #b89b5e;
  border-right: 36px solid transparent;
  position: absolute;
  top: calc(100% + 18px);
  left: 0;
  content: "";
  display: none;
}

@media only screen and (min-width: 1025px) {
  .lng-item.is-active:after {
    display: block;
  }
}

.search-grid {
  margin-bottom: 2.25rem;
}

.search-item {
  width: 100%;
  border: 1px solid #d3d3d1;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  transition: border-color 0.3s 0.2s ease;
  display: flex;
  padding: 12px;
}

@media only screen and (min-width: 768px) {
  .search-item {
    padding: 0;
  }
}

.search-item__number {
  width: 100%;
  font-size: 12.5rem;
  font-weight: 200;
  font-family: 'RistrettoPro';
  flex-shrink: 0;
  width: 100px;
  position: relative;
  color: #dbcdae;
  overflow: hidden;
  display: none;
  transition: color 0.5s ease;
}

@media only screen and (min-width: 768px) {
  .search-item__number {
    display: block;
  }
}

.search-item__number span {
  z-index: 5;
  position: relative;
  display: inline-block;
}

.search-item__number:before {
  position: absolute;
  left: -83px;
  top: 38px;
  width: 130px;
  height: 130px;
  content: "";
  background-color: transparent;
  border-top: 2px solid #b89b5e;
  border-left: 2px solid #dbcdae;
  -webkit-transform: rotate(130deg);
  transform: rotate(130deg);
}

.search-item__descr {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.search-item__title {
  color: #2d3b4e;
  margin-bottom: 20px;
}

.search-item__text {
  color: #2d3b4e;
}

.search-item:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: -1;
  left: 0;
  top: 0;
  box-shadow: 0px 9px 45px 2px rgba(128, 128, 128, 0.76);
  opacity: 0;
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.search-item .more-link__image-holder {
  opacity: 0;
  top: initial;
  bottom: 10px;
  right: 10px;
  transition: opacity 0.5s ease;
  z-index: 5;
}

.search-item:hover {
  transition: border-color 0.2s 0s ease;
  z-index: 15;
  border-color: transparent;
}

.search-item:hover .search-item__number {
  color: #b89b5e;
}

.search-item:hover:after {
  opacity: 1;
  transform: scaleX(1.02) scaleY(1.12);
}

.search-item:hover .law-item__bg {
  fill: #f1ebdf;
}

.search-item:hover .more-link__image-holder {
  opacity: 1;
}

.search-sum {
  margin-bottom: 3.75rem;
  color: #2d3b4e;
}

.search-sum__sum {
  color: #b89b5e;
}

.services {
  margin-bottom: 3.125rem;
}

.services-name {
  display: block;
  background-color: #19937f;
  width: 100%;
  flex-shrink: 0;
  margin: 14px 0;
  min-height: 112px;
  position: relative;
  padding-top: 42px;
  backface-visibility: hidden;
}

@media only screen and (min-width: 768px) {
  .services-name {
    width: 200px;
    min-height: 144px;
  }
}

@media only screen and (min-width: 1440px) {
  .services-name {
    width: 242px;
  }
}

.services-name__text {
  color: #fff;
  text-align: center;
}

.services-name:after {
  position: absolute;
  width: 1px;
  background-color: #fff;
  height: 40px;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  margin: 0 auto;
}

.services-block {
  display: flex;
}

.services-group {
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 768px) {
  .services-group {
    flex-direction: row;
  }
}

.services-group__items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media only screen and (min-width: 768px) {
  .services-group__items {
    justify-content: flex-start;
  }
}

.left-menu {
  margin-right: 0;
  width: 310px;
  flex-shrink: 0;
  position: fixed;
  transform: translateX(-240%);
  transition: transform 0.8s ease;
  z-index: 15;
  padding-right: 10px;
  top: 58px;
  left: 0;
  height: 100%;
  padding-left: 16px;
  background-color: #fff;
}

@media only screen and (min-width: 1025px) {
  .left-menu {
    padding-right: 0;
    margin-right: 14px;
    display: block;
    padding-left: 0;
    transform: translateX(0%);
    position: relative;
  }
}

@media only screen and (min-width: 1440px) {
  .left-menu {
    margin-right: 34px;
    width: 340px;
  }
}

.left-menu.is-active {
  transform: translateX(0%);
}

.left-menu__list, .left-menu__list-sub {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.left-menu__link {
  height: 60px;
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  position: relative;
}

.left-menu__list > .left-menu__item > .left-menu__link {
  transition: color 0.5s ease;
  border-bottom: 2px solid #f1ebdf;
}

.left-menu__list > .left-menu__item > .left-menu__link:hover {
  color: #19937f;
}

.left-menu__list > .left-menu__item > .left-menu__link:hover:after {
  background-color: #19937f;
}

.left-menu__list > .left-menu__item > .left-menu__link:after {
  position: absolute;
  left: 0;
  bottom: -2px;
  content: "";
  height: 2px;
  width: 100%;
  transform: rotateY(86deg);
  transform-origin: left;
  transition: background-color 0.5s ease, transform 0.6s ease;
  background-color: #b89b5e;
}

.left-menu__list > .left-menu__item > .left-menu__link.is-active {
  color: #19937f;
}

.left-menu__list > .left-menu__item > .left-menu__link.is-active:after {
  transform: rotateY(0);
  background-color: #19937f;
}

.left-menu__list > .left-menu__item > .left-menu__list-sub {
  border-bottom: 1px solid #19937f;
}

.left-menu__item > .left-menu__link {
  font-size: 1.75rem;
  font-weight: 500;
  font-family: 'RistrettoPro';
}

.left-menu__item > .left-menu__list-sub > .left-menu__list-sub-item > .left-menu__link {
  padding-left: 20px;
  font-size: 1.5rem;
  font-family: 'RistrettoPro';
  color: #2d3b4e;
  font-weight: 500;
  transition: color 0.5s ease;
  border-bottom: 1px solid #e1dbcd;
}

.left-menu__item > .left-menu__list-sub > .left-menu__list-sub-item > .left-menu__link:hover {
  color: #19937f;
}

.left-menu__item > .left-menu__list-sub > .left-menu__list-sub-item > .left-menu__link.is-active {
  color: #19937f;
}

.left-menu__item > .left-menu__list-sub > .left-menu__list-sub-item > .left-menu__list-sub > .left-menu__list-sub-item .left-menu__link {
  font-family: 'Open Sans';
  position: relative;
  background-color: #e1dbcd;
  color: #2d3b4e;
  padding-left: 40px;
  font-size: 14px;
  transition: background-color 0.5s ease, color 0.3s ease;
  margin-bottom: 1px;
}

.left-menu__item > .left-menu__list-sub > .left-menu__list-sub-item > .left-menu__list-sub > .left-menu__list-sub-item .left-menu__link:before {
  position: absolute;
  z-index: 1;
  content: "";
  left: 20px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  background-color: #2d3b4e;
  height: 1px;
  width: 10px;
  transition: background-color 0.5s ease;
}

.left-menu__item > .left-menu__list-sub > .left-menu__list-sub-item > .left-menu__list-sub > .left-menu__list-sub-item .left-menu__link.is-active {
  background-color: #b89b5e;
  color: #fff;
}

.left-menu__item > .left-menu__list-sub > .left-menu__list-sub-item > .left-menu__list-sub > .left-menu__list-sub-item .left-menu__link.is-active:after {
  background-color: #b89b5e;
}

.left-menu__item > .left-menu__list-sub > .left-menu__list-sub-item > .left-menu__list-sub > .left-menu__list-sub-item .left-menu__link.is-active:before {
  background-color: #fff;
}

.left-menu__item > .left-menu__list-sub > .left-menu__list-sub-item > .left-menu__list-sub > .left-menu__list-sub-item .left-menu__link:hover {
  background-color: #b89b5e;
  color: #fff;
}

.left-menu__item > .left-menu__list-sub > .left-menu__list-sub-item > .left-menu__list-sub > .left-menu__list-sub-item .left-menu__link:hover:after {
  background-color: #b89b5e;
}

.left-menu__item > .left-menu__list-sub > .left-menu__list-sub-item > .left-menu__list-sub > .left-menu__list-sub-item .left-menu__link:hover:before {
  background-color: #fff;
}

.nav {
  display: none;
}

@media only screen and (min-width: 1025px) {
  .nav {
    display: flex;
  }
}

.nav__link {
  text-transform: uppercase;
  text-decoration: none;
  color: #b89b5e;
  padding: 0 0.25rem;
  margin: 0 0.5rem;
  position: relative;
  transition: color 0s 0s ease;
  letter-spacing: normal;
}

@media only screen and (min-width: 1280px) {
  .nav__link {
    margin: 0 1rem;
    letter-spacing: 2px;
  }
}

.nav__link:hover {
  color: transparent;
  transition: color 0.3s 0.3s ease;
}

.nav__link:hover:after {
  transform: scale(1);
}

.nav__link:hover span:before {
  width: 100%;
}

.nav__link span {
  position: relative;
  display: inline-block;
}

.nav__link span:before {
  content: attr(data-attr-text);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  color: #19937f;
  transition: width .4s ease-out;
  overflow: hidden;
  font-family: 'RistrettoPro';
  font-size: 1.75rem;
  font-weight: 600;
  white-space: nowrap;
}

.nav__link:after {
  bottom: 0;
  left: 0.25rem;
  width: 22px;
  height: 1px;
  background-color: #19937f;
  position: absolute;
  content: "";
  transform-origin: left;
  transform: rotateY(90deg);
  transition: transform 0.5s ease;
}

.prices {
  margin-bottom: 3.25rem;
}

.prices__bottom {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 1.875rem;
}

.prices__bottom .btn--big {
  padding: 0 12px;
}

@media only screen and (min-width: 1440px) {
  .prices__bottom .btn--big {
    padding: 0 42px;
  }
}

.prices__bottom--service {
  justify-content: space-between;
  padding-top: 2.5rem;
  margin-bottom: 0;
  flex-direction: column;
}

@media only screen and (min-width: 768px) {
  .prices__bottom--service {
    flex-direction: row;
  }
}

.prices__bottom--service .prices__text {
  text-align: left;
}

.prices__text {
  color: #2d3b4e;
  line-height: 100%;
  width: 100%;
  max-width: 590px;
  text-align: center;
  margin-bottom: 1.5rem;
}

.m-menu__lngs {
  display: flex;
  justify-content: space-around;
}

.m-menu__lng {
  color: #b89b5e !important;
  font-size: 1.55rem !important;
  font-weight: 600 !important;
}

.m-menu__link {
  color: #b89b5e !important;
  font-size: 1.25rem !important;
  font-weight: 600 !important;
}

.m-menu__item {
  border-color: #19937f !important;
}

.mm-title {
  color: #19937f !important;
  font-size: 1.75rem !important;
  font-weight: 600 !important;
}

.mm-menu {
  background-color: #fff !important;
}

.main-slider-wrapper {
  position: relative;
  margin-bottom: 44px;
  overflow: hidden;
}

.main-slider-wrapper__bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 1150px;
  height: 690px;
  z-index: 10;
  fill: #001f1a;
  opacity: 0.7;
  display: none;
  transform: translateX(-730px) rotateY(180deg);
  border-radius: 0 0 0 520px;
}

@media only screen and (min-width: 1025px) {
  .main-slider-wrapper__bg {
    display: block;
  }
}

@media only screen and (min-width: 1280px) {
  .main-slider-wrapper__bg {
    transform: translateX(-312px);
    border-radius: 0;
  }
}

@media only screen and (min-width: 1440px) {
  .main-slider-wrapper__bg {
    transform: translateX(0);
  }
}

.main-slider {
  position: relative;
  height: 500px;
}

@media only screen and (min-width: 768px) {
  .main-slider {
    height: 742px !important;
  }
}

.main-slider .btn--big {
  width: 86%;
  max-width: 310px;
}

@media only screen and (min-width: 768px) {
  .main-slider .btn--big {
    width: 100%;
  }
}

.main-slider__item {
  position: relative;
  outline: none;
  border: none !important;
  height: 500px !important;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 90%;
}

@media only screen and (min-width: 768px) {
  .main-slider__item {
    background-position: 100%;
    height: 742px !important;
  }
}

.main-slider__item:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.9;
  z-index: 5;
  display: none;
}

@media only screen and (min-width: 768px) {
  .main-slider__item:after {
    display: block;
    background: linear-gradient(90deg, rgba(0, 31, 26, 0.6), rgba(0, 31, 26, 0.4), rgba(0, 31, 26, 0.4), rgba(0, 31, 26, 0.2), transparent, transparent);
  }
}

@media only screen and (min-width: 1025px) {
  .main-slider__item:after {
    background: linear-gradient(90deg, #001f1a, rgba(0, 31, 26, 0.8), rgba(0, 31, 26, 0.6), rgba(0, 31, 26, 0.4), transparent, transparent);
  }
}

.main-slider .title {
  height: 41px;
  font-size: 28px;
}

@media only screen and (min-width: 768px) {
  .main-slider .title {
    font-size: 3rem;
  }
}

.main-slider__item-content {
  position: absolute;
  bottom: 0;
  padding-bottom: 16px;
  left: 0;
  right: 0;
  padding-top: 6px;
  margin: 0 auto;
  margin-left: 0;
  width: 100%;
  max-width: 100%;
  z-index: 15;
  color: #fff;
  text-align: center;
  opacity: 0;
  transition: opacity 0.6s ease, transform 0s 0.6s ease;
  transform: translateY(200px);
  background-color: rgba(0, 31, 26, 0.6);
}

@media only screen and (min-width: 768px) {
  .main-slider__item-content {
    padding-bottom: 0;
    max-width: 620px;
    margin-left: 20px;
    padding-top: 0;
    background-color: transparent;
    bottom: 16px;
  }
}

@media only screen and (min-width: 1025px) {
  .main-slider__item-content {
    margin-left: auto;
  }
}

@media only screen and (min-width: 1280px) {
  .main-slider__item-content {
    bottom: 50px;
  }
}

.main-slider__item-text {
  line-height: 20px;
  font-size: 16px;
  font-family: 'Open Sans';
  padding-top: 16px;
  margin-bottom: 1.375rem;
  height: 90px;
  padding-bottom: 8px;
  overflow: hidden;
  font-weight: 300;
}

@media only screen and (min-width: 768px) {
  .main-slider__item-text {
    font-size: 18px;
    height: 74px;
  }
}

.main-slider:after {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 0;
  border-bottom: 270px solid #b89b5e;
  border-left: 270px solid transparent;
  opacity: 0.6;
  content: "";
  z-index: 1;
  display: none;
}

@media only screen and (min-width: 768px) {
  .main-slider:after {
    display: flex;
  }
}

.slick-slide img {
  width: 1820px;
}

.slick-active .main-slider__item-content {
  opacity: 1;
  transition: opacity 0.8s 0.6s ease, transform 1.2s 0.6s ease;
  transform: translateY(0);
}

.slider-nav {
  position: absolute;
  left: 0;
  top: 30px;
  width: 100%;
  z-index: 15;
  max-height: calc(100% - 126px);
  overflow: hidden;
}

.slick-dots {
  position: absolute;
  bottom: 26px;
  right: 0;
  justify-content: center;
  padding: 0;
  align-items: center;
  list-style-type: none;
  z-index: 5;
  display: none !important;
}

@media only screen and (min-width: 768px) {
  .slick-dots {
    display: flex !important;
  }
}

.slick-dots button {
  width: 10px;
  background-color: #fff;
  height: 6px;
  font-size: 0;
  outline: none;
  border: none;
  position: absolute;
  left: 0;
  top: -2px;
  transition: width 0.3s ease;
}

.slick-dots li {
  height: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 15px;
  width: 50px;
  position: relative;
  border-top: 2px solid #fff;
  background-color: transparent;
}

.slick-dots li:hover button {
  width: 50%;
}

.slick-dots .slick-active {
  cursor: default;
  pointer-events: none;
}

.slick-dots .slick-active button {
  width: 100%;
}

.phone-block {
  display: flex;
  margin-left: 10px;
  flex-direction: column;
}

.phone-block a {
  color: #fff;
  text-decoration: none;
  font-weight: 700;
}

.phone-block a:hover {
  color: #b89b5e;
}

.feed-block__title {
  margin-bottom: 10px;
  color: #19937f;
}

.sub-phones__del {
  color: #19937f;
  margin: 0 2px;
}

.sub-phones__item {
  text-decoration: none;
  font-weight: bold;
}

.sub-phones__item:hover {
  color: #b89b5e;
  transition: color 0.3s ease;
}

.sub-nav {
  position: relative;
  display: none;
}

@media only screen and (min-width: 1025px) {
  .sub-nav {
    display: block;
  }
}

.sub-nav__info-logo {
  display: none;
}

@media only screen and (min-width: 768px) {
  .sub-nav__info-logo {
    display: block;
  }
}

.sub-nav__list {
  width: 100%;
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-width: 360px;
  position: relative;
  overflow: overlay;
}

.sub-nav__info-block {
  display: flex;
  margin-bottom: 12px;
}

.sub-nav__info-block img {
  display: none;
}

@media only screen and (min-width: 1280px) {
  .sub-nav__info-block img {
    display: block;
  }
}

.sub-nav__item {
  padding: 4px 0;
}

.sub-nav__item .sub-nav__list {
  padding-left: 20px;
  margin-top: 4px;
}

.sub-nav__item .sub-nav__list .sub-nav__link:before {
  font-size: 1.5rem;
}

.sub-nav__item .sub-nav__list:after, .sub-nav__item .sub-nav__list:before {
  position: absolute;
  content: "";
  width: 100%;
  background-color: #fff;
  left: 0;
  transform: rotateY(90deg);
  transform-origin: center;
}

.sub-nav__item .sub-nav__list:before {
  height: 2px;
  top: 0;
  transition: transform 0.8s 0s ease;
}

.sub-nav__item .sub-nav__list:after {
  height: 1px;
  bottom: 0;
  transition: transform 0.8s 0s ease;
}

.sub-nav__item.js-active > .sub-nav__link {
  color: #fff;
  pointer-events: none;
}

.sub-nav__item.js-active .sub-nav__list:after, .sub-nav__item.js-active .sub-nav__list:before {
  transform: rotateY(0deg);
}

.sub-nav__link {
  color: #b89b5e;
  text-decoration: none;
  position: relative;
  transition: color 0s 0s ease;
}

.sub-nav__link:before {
  content: attr(data-attr-text);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  color: #fff;
  transition: width .4s ease-out, color 0.3s ease;
  overflow: hidden;
  font-family: 'RistrettoPro';
  font-size: 1.75rem;
  font-weight: 500;
  white-space: nowrap;
}

.sub-nav__link:hover {
  color: transparent;
  transition: color 0.3s 0.3s ease;
}

.sub-nav__link:hover:before {
  width: 100%;
}

.sub-nav__link:active:before {
  color: #d9d9d9;
  transition: color 0s 0s ease;
}

.tnx-feed-slider-wrapper {
  width: 100%;
  background-repeat: no-repeat;
  background-position: 0 0%;
  background-size: cover;
  position: relative;
}

.slick-active .tnx-feed-slider__item-name {
  opacity: 1;
  transition: opacity 0.4s 0.6s ease;
}

.tnx-feed {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 44px 0;
  background-position: 80% 0;
  background-repeat: no-repeat;
  background-size: cover;
}

.tnx-feed-slider {
  padding-bottom: 0;
  width: 100%;
}

@media only screen and (min-width: 1025px) {
  .tnx-feed-slider {
    padding-bottom: 4rem;
  }
}

.tnx-feed-slider__item {
  padding-top: 3.75rem;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: default;
}

.tnx-feed-slider__item-text {
  text-align: center;
  width: 100%;
  height: 200px;
  overflow: hidden;
  margin-bottom: 2.5rem;
  max-width: 1100px;
  padding: 0 10px;
  font-weight: 300;
}

@media only screen and (min-width: 768px) {
  .tnx-feed-slider__item-text {
    height: 102px;
  }
}

.tnx-feed-slider__item-name {
  text-align: center;
  width: 100%;
  max-width: 1100px;
  opacity: 0;
  transition: opacity 0.2s 0s ease;
}

.tnx-feed-slider__arrow-wrapper {
  position: absolute;
  bottom: 1rem;
  z-index: 55;
  cursor: pointer;
  left: 0;
  right: 0;
  width: 52px;
  margin: 0 auto;
  display: none;
}

@media only screen and (min-width: 768px) {
  .tnx-feed-slider__arrow-wrapper {
    display: block;
  }
}

@media only screen and (min-width: 1025px) {
  .tnx-feed-slider__arrow-wrapper {
    bottom: 5rem;
  }
}

.tnx-feed-slider__arrow-wrapper--left {
  transform: rotate(180deg) translateX(250px);
}

.tnx-feed-slider__arrow-wrapper--right {
  transform: translateX(250px);
}

.tnx-feed-slider__arrow-wrapper:hover .tnx-feed-slider__image-holder:before {
  animation: lines_hover_animation 0.5s alternate;
}

.tnx-feed-slider__arrow-wrapper:hover .tnx-feed-slider__image-holder .tnx-feed-slider__arrow {
  animation: blink 0.8s alternate;
}

.tnx-feed-slider__image-holder {
  width: 86px;
  height: 52px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto 0;
  overflow: hidden;
}

.tnx-feed-slider__image-holder:before {
  top: 0;
  bottom: 0;
  margin: auto 0;
  right: 26px;
  height: 1px;
  content: "";
  position: absolute;
  width: 64px;
  background-color: #b89b5e;
  z-index: -1;
}

.tnx-feed-slider__arrow, .tnx-feed-slider__square {
  position: absolute;
  top: 0;
  fill: #b89b5e;
}

.tnx-feed-slider__square {
  width: 52px;
  height: 52px;
  opacity: 0.3;
  right: 0;
}

.tnx-feed-slider__arrow {
  width: 18px;
  height: 34px;
  right: 16px;
  bottom: 0;
  margin: auto;
}

.work-scheme {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.work-scheme__item-number {
  font-size: 12.5rem;
  color: #b89b5e;
  position: absolute;
  left: 24px;
  top: -16px;
  line-height: 100%;
  font-size: 7.5rem;
}

@media only screen and (min-width: 768px) {
  .work-scheme__item-number {
    font-size: 12.5rem;
    top: -40px;
  }
}

.work-scheme__item-number span {
  position: relative;
  z-index: 1;
}

.work-scheme__item-number:after {
  position: absolute;
  left: -38px;
  top: 18px;
  width: 60px;
  height: 60px;
  content: "";
  background-color: transparent;
  border-top: 2px solid #4b5545;
  display: none;
  border-left: 2px solid transparent;
  transform: rotate(130deg);
}

@media only screen and (min-width: 768px) {
  .work-scheme__item-number:after {
    width: 130px;
    height: 130px;
    left: -98px;
    top: 39px;
    display: block;
  }
}

.work-scheme__item-number:before {
  position: absolute;
  left: -38px;
  top: 18px;
  width: 60px;
  height: 60px;
  content: "";
  background-color: transparent;
  border-top: 2px solid #b89b5e;
  border-left: 2px solid #6c6a4d;
  transform: rotate(130deg);
}

@media only screen and (min-width: 768px) {
  .work-scheme__item-number:before {
    width: 130px;
    height: 130px;
    left: -83px;
    top: 34px;
  }
}

.work-scheme__grid {
  display: flex;
  margin-bottom: 2.5rem;
  margin-top: 4rem;
  flex-wrap: wrap;
  justify-content: space-around;
}

@media only screen and (min-width: 1440px) {
  .work-scheme__grid {
    font-weight: normal;
  }
}

.work-scheme__item {
  padding-left: 60px;
  position: relative;
  width: initial;
  min-width: 310px;
  margin-bottom: 3.75rem;
  max-width: 100%;
}

@media only screen and (min-width: 768px) {
  .work-scheme__item {
    width: 350px;
    padding-left: 120px;
  }
}

@media only screen and (min-width: 1440px) {
  .work-scheme__item {
    width: initial;
  }
}

.work-scheme__item-text {
  color: #fff;
  width: 230px;
}

.inner-news-page .view-text {
  margin-bottom: 2.625rem;
}

.widget-block {
  margin-bottom: 5.125rem;
  display: flex;
  align-items: center;
}

.widget-block__title {
  margin-right: 8px;
  color: #2d3b4e;
}

.date-block {
  padding-bottom: 1.75rem;
  padding-top: 1.25rem;
  margin-bottom: 1.25rem;
  position: relative;
}

.date-block:after {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 70px;
  height: 3px;
  background-color: #eae1ce;
  content: "";
  bottom: 0;
}

.mCSB_dragger_bar {
  background-color: #aaa;
  transition: transform 0.5s ease, background-color 0.5s ease !important;
}

.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #999;
  transform: scaleX(1.5);
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #aaa;
}

.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent;
}

.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #aaa;
}

.mCSB_scrollTools {
  width: 10px;
}

.mCSB_inside > .mCSB_container {
  margin-right: 10px;
}

._hover-line {
  position: relative;
  overflow: hidden;
}

._hover-line:after {
  position: absolute;
  content: "";
  background-color: #19937f;
  height: 3px;
  width: 100%;
  bottom: 0;
  left: 0;
  transition: transform 0.2s ease;
}

._hover-line:before {
  position: absolute;
  content: "";
  background-color: #19937f;
  height: 3px;
  width: 100%;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  transition: transform 0.2s 0s ease;
}

._hover-line:hover:after {
  transform: translateX(100%);
  transition: transform 0.25s ease;
}

._hover-line:hover:before {
  transform: translateX(0%);
  transition: transform 0.25s 0.25s ease;
}

._mgb60 {
  margin-bottom: 3.75rem;
}

._color-green {
  color: #19937f;
}

._color-green .btn__hover-effect:before, ._color-green .btn__hover-effect:after {
  background-color: #19937f;
}

._color-green:hover {
  color: #fff;
}

._color-white {
  color: #fff;
}

._border-color-green {
  border-color: #19937f;
}

._tac {
  text-align: center;
}

._tal {
  text-align: left;
}
