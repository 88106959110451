////
/// @group _partials/_buttons.scss
////

// scss
// ================================




.btn{
	display: inline-block;
	background-color: transparent;
	border: 2px solid $c-white;
	height: 50px;
	line-height: 50px;
	background-color: transition;
	outline: none;
	color: $c-white;
	position: relative;
	z-index: 1;
	text-decoration: none;
	backface-visibility: hidden;
	transition: border-color 0.6s ease;
	
	&:before{
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		content: "";
		background-color: $c-green;
		z-index: 1;
		transform: rotateY(90deg);
		opacity: 0;
		transition: opacity 0.6s ease-in-out, transform 0s 0.6s ease, background-color 0.3s ease;
	}
	&__hover-effect{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		&:before,
		&:after{
			position: absolute;
			content: "";
			background-color: $c-white;
			top: 50%;
			margin-top: -0.5px;
			width: 44px;
			height: 1px;
			transition: transform .7s, opacity 0.3s ease-in-out;
		}
		&:before{
			left: -22px;
		}
		&:after{
			right: -22px;
		}
	}
	&__content{
		font-size: 22px;
		font-weight: 500;
		text-transform: uppercase;
		font-family: 'RistrettoPro';
		letter-spacing: 1px;
		position: relative;
		z-index: 2;
		text-align: center;
		@include media(md){
			font-size: 24px;
		}
	}
	&--disabled{
		pointer-events: none;
	}
	&--small{
		
	}
	&--big{
		padding: 0 10px;
		width: 100%;
		max-width: 388px;
		@include media(md){
			padding: 0 42px;
		}
	}
	&--w100{
		width: 100%;
	}
	&:hover{
		border-color: $c-green;
		&:before{
			transform: rotateY(0deg);
			transition: transform 0.3s ease-in-out, opacity 0s ease, background-color 0.3s ease;
			opacity: 1;
		}
		.btn__hover-effect{
			&:before{
				transform: translate(-22px);
				opacity: 0;
				transition: transform .5s, opacity 0.3s ease-in-out
			}
			&:after{
				transform: translate(22px);
				opacity: 0;
				transition: transform .5s, opacity 0.3s ease-in-out
			}
		}
	}
	&:active{
		border-color: darken($c-green, 8%);
		transition: all 0s;
		&:before{
			background-color: darken($c-green, 8%);
			transition: all 0s;
		}
	}
}

