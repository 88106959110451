////
/// @group _partials/pagination.scss
////

// scss
// ================================

.pagination {
	text-align: center;
	display: flex;
	justify-content: center;
	&__btn{
		text-decoration: none;
		color: #dbcdae;
		border: 2px solid transparent;
		width: 30px;
		height: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		align-content: center;
		margin: 0 5px;
		transition: color 0.4s ease;
		@include media($md){
			margin: 0 10px;
			width: 50px;
			height: 50px;
		}

		&.is-active{
			border-color: #eae1ce;
			cursor: default;
			user-select: none;
			color: #b89b5e;
		}
		&:hover{
			color: #b89b5e;
		}
	}
	&__arrow-wrapper{
	position: relative;
		bottom: rem(0);
		z-index: 55;
		cursor: pointer;
		width: 86px;
		display: none;
		@include media($md){
			display: block;
		}

		&--left{
			transform: rotate(180deg);
			margin-right: rem(110);
		}
		&--right{
			margin-left: rem(110);
		}
		&:hover{
			.pagination__image-holder{
				&:before{
					animation: lines_hover_animation 0.5s alternate;
				}
				.pagination__arrow{
					animation: blink 0.8s alternate;
				}
			}
		}
		&--disabled{
			cursor: default;
			pointer-events: none;
			.pagination__arrow,
			.pagination__image-holder:before{
				opacity: 0.6;
			}
		}
	}
	&__image-holder{
		width: 86px;
		height: 52px;
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto 0;
		overflow: hidden;
		&:before{
			top: 0;
			bottom: 0;
			margin: auto 0;
			right: 26px;
			height: 1px;
			content: "";
			position: absolute;
			width: 64px;
			background-color: $c-brown;
			z-index: -1;
		}
	}
	&__arrow,
	&__square{
		position: absolute;
		top: 0;
		fill: $c-brown;
	}
	&__square{
		width: 52px;
		height: 52px;
		opacity: 0.3;
		right: 0;
	}
	&__arrow{
		width: 18px;
		height: 34px;
		right: 16px;
		bottom: 0;
		margin: auto;
	}
}
