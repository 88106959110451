////
/// @group _partials/view-section.scss
////


// scss
// ================================

.view-section {
	position: relative;
	padding-top: 0;
	@include media($def){
		padding-top: 78px;
	}
}
