////
/// @group _partials/form.scss
////


// scss
// ================================

.form {
	position: relative;
	font-size: 14px;

	&__group {
		display: block;
		position: relative;
		margin: 0 0 1.6rem;
		& & {
			margin: 0 0 .8rem;
		}
	}

	&__label {
		cursor: default;
		display: inline-block;
		vertical-align: top;
		user-select: none;
		margin-bottom: .4rem;
	}

	&__caption {
		font-size: .8em;
		margin-bottom: .4em;
	}

	&__info {
		display: none;
		pointer-events: none;
		position: absolute;
		top: 0;
		right: 0;
		font-size: .7em;
		background-color: #000;
		color: #fff;
		padding: .1em .3em;
		input:focus ~ &,
		textarea:focus ~ &,
		select:focus ~ & {
			display: block;
		}
	}
}

label.form__label {
	cursor: pointer;
}


.control-holder {
	position: relative;

	label.has-error {
		vertical-align: top;
		user-select: none;
		font-size: .75em;
		line-height: 1em;
		padding: .1em .5em;
		background-color: #000;
		display: none!important;
		color: #fff;
	}

	&--text {
		input, textarea, select {
			display: block;
			width: 100%;
		}
		input, select{
			height: 50px;
			line-height: 50px;
			border-radius: 0;
			outline: none;
			padding: 0 14px;
			background-color: $c-grey;
			transition: background-color 0.5s ease, border-color 0.4s ease;
			border: 1px solid $c-grey;
			font-family: "Open Sans", Arial;
			&:focus{
				background-color: $c-white;
			}
			&.has-error{
				border-bottom: 1px solid red;
			}
		}
		textarea {
			border-radius: 0;
			outline: none;
			height: 120px;
			padding: 14px;
			background-color: $c-grey;
			transition: background-color 0.5s ease, border-color 0.4s ease;
			border: 1px solid $c-grey;
			font-family: "Open Sans", Arial;
			&:focus{
				background-color: $c-white;
			}
		}

		
	}

	&--flag {
		label {
			user-select: none;
			margin-right: 1em;
			&:only-child,
			&.has-error {
				cursor: default;
				margin-right: 0;
			}
		}
		ins {
			position: relative;
			display: inline-block;
			vertical-align: text-bottom;
			width: 1.2em;
			height: 1.2em;
			background-color: #fff;
			border: 1px solid;
		}
		input {
			position: absolute;
			top: 0;
			left: 0;
			width: 0;
			height: 0;
			&[type="radio"] {
				~ ins {
					border-radius: 50%;
				}
			}
			&:checked {
				~ ins {
					background-color: #000;
					box-shadow: inset 0 0 0 3px #fff;
				}
			}
			&:disabled {
				~ ins,
				~ span {
					opacity: .5;
					cursor: not-allowed;
				}
			}
		}
	}

	&--file {
		label {
			position: relative;
			display: inline-block;
			vertical-align: top;
		}

		input {
			position: absolute;
			outline: none;
			top: 0;
			left: 0;
			width: 0;
			height: 0;
			opacity: 0;
		}

		._ellipsis {
			max-width: 200px;
			width: auto;
			display: inline-block;
			vertical-align: top;
		}
	}
}
