.search-form{
	display: flex;
	position: fixed;
	top: 58px;
	left: 0;
	transform: translateX(200%);
	width: 100%;
	transition: transform 1s ease;
	@include media($def){
		position: relative;
		transform: translateY(0%);
		width: initial;
		top: 0;
	}
	&.is-active{
		transform: translateY(0%);
	}
	&__content-holder{
		width: 110px;
		display: flex;
		justify-content: center;
		align-items: center;
		letter-spacing: 2px;
		position: relative;
		z-index: 2;
	}
	&__btn{
		border: 0;
		outline: none;
		background-color: transparent;
		padding: 0;
		text-transform: uppercase;
		background-color: $c-green;
		color: $c-white;
		height: 40px;
		position: relative;
		line-height: 40px;
		position: absolute;
		right: 0;
		top: 0;
		@include media($def){
			position: relative;
		}
		&:before{
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			content: "";
			background-color: $c-brown;
			z-index: 1;
			transform: rotateY(90deg);
			opacity: 0;
			transition: opacity 0.6s ease-in-out, transform 0s 0.6s ease, background-color 0.3s ease;
		}
		&:hover{
			&:before{
				transform: rotateY(0deg);
				transition: transform 0.3s ease-in-out, opacity 0s ease, background-color 0.3s ease;
				opacity: 1;
			}
		}
		&:active{
			&:before{
				transform: rotateY(0deg);
				opacity: 1;
				background-color: darken($c-brown, 10%);
				transition: all 0s ease;
			}
		}
	}
	.form__group{
		margin-bottom: 0;
		width: 100%;
		@include media($def){
			width: 180px;
		}
	}
	input{
		padding-right: 30px;
		height: 40px;
		line-height: 40px;
		border-right: none;
	}

}