.more-link{
	padding-right: rem(70);
	position: relative;
	text-decoration: none;
	display: inline-flex;
	align-items: center;
	height: 26px;

	&__image-holder{
		width: 46px;
		height: 26px;
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto 0;
		overflow: hidden;
		&:before{
			top: 0;
			bottom: 0;
			margin: auto 0;
			right: 14px;
			height: 1px;
			content: "";
			position: absolute;
			width: 32px;
			background-color: $c-brown;
			z-index: -1;
		}
	}
	&__text{
		text-transform: uppercase;
		color: $c-brown;
		letter-spacing: 1px;
	}
	&__arrow,
	&__square{
		position: absolute;
		top: 0;
		fill: $c-brown;
	}
	&__square{
		width: 26px;
		height: 26px;
		opacity: 0.3;
		right: 0;
	}
	&__arrow{
		width: 10px;
		height: 18px;
		right: 6px;
		bottom: 0;
		margin: auto;
	}
	&--law{
		position: absolute;
		right: 10px;
		bottom: 10px;
		width: calc(100% - 20px);
		display: flex;
		justify-content: center;
		padding-right: 0;
		opacity: 0;
		transition: opacity 0.5s ease;
		z-index: 15;
		.more-link__text{
			color: $c-white;
		}
		.more-link__arrow{
			fill: $c-white;
		}
		.more-link__image-holder{
			&:before{
				background-color: $c-white;
			}
		}
	}
	&:hover{
		.more-link__image-holder{
			&:before{
				animation: lines_hover_animation 0.5s alternate;
			}
			.more-link__arrow{
				animation: blink 0.8s alternate;
			}
		}
	}
}