////
/// @group _partials/view-footer.scss
////


// scss
// ================================

.view-footer {
	position: relative;
	&__pre-footer-wrapper{
		background-color: $c-lbrown;
		padding: rem(54) 0;
		background-image: url("../images/line_t1.png");
		background-repeat: no-repeat;
		background-position: 100% 100%;
	}
	&__pre-footer{
		display: flex;
		justify-content: space-between;
	}
	&__logo{
		margin-bottom: rem(26);
		display: none;
		@include media($def){
	    	display: inline-block;
	    }
	}
	&__phones{
		margin-bottom: rem(36);
	}
	&__contacts{
		margin-bottom: rem(40);
	}
	&__menu-block{
		display: none;
		@include media($def){
	    	display: block;
	    }
	}
	&__bottom{
		padding: rem(44) 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-direction: column;
		@include media($def){
	    	flex-direction: row;
	    }
	}
	&__main-block{
	    width: 100%;
	    @include media($def){
	    	width: 34.4%;
	    }
	}
}
