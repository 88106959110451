._hover-line{
	position: relative;
	overflow: hidden;
	&:after{
		position: absolute;
		content: "";
		background-color: $c-green;
		height: 3px;
		width: 100%;
		bottom: 0;
		left: 0;
		transition: transform 0.2s ease;
	}
	&:before{
		position: absolute;
		content: "";
		background-color: $c-green;
		height: 3px;
		width: 100%;
		bottom: 0;
		left: 0;
		transform: translateX(-100%);
		transition: transform 0.2s 0s ease;
	}
	&:hover{
		&:after{
			transform: translateX(100%);
			transition: transform 0.25s ease;
		}
		&:before{
			transform: translateX(0%);
			transition: transform 0.25s 0.25s ease;
		}
	}
}
._mgb60{
	margin-bottom: rem(60);
}
._color-green{
	color: $c-green;
	.btn__hover-effect:before, .btn__hover-effect:after{
		background-color: $c-green;
	}
	&:hover{
		color: $c-white;
	}
}
._color-white{
	color: $c-white;
}
._border-color-green{
	border-color: $c-green;
}
._tac{
	text-align: center;
}
._tal{
	text-align: left;
}