@keyframes lines_hover_animation {
  0% {
  	transform: translateX(0%);
  }
  50%{
  	transform: translateX(100%);
  }
  51% {
	  transform: translateX(-100%);
  }
  100% {
	  transform: translateX(0%);
  }	
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50%{
    opacity: 0;
  }
  51% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  } 
}
.abus-item__title:after{
    transform: rotateY(-90deg);
    transform-origin: left;
    transition: transform 2s ease;
  }
  .law-item{
    transform: rotateX(-90deg);
    transform-origin: top;
  }
  .work-scheme__item{
    transform-origin: left;
    transform: scaleX(0);
    transition: transform 1.2s ease;
  }
  .advantages-item__image:after{
    transform: scaleX(0.16);
    transition: transform 1.2s ease;
    transform-origin: left;
  }
.js-inviewed{
  &.abus-item__title:after{
    transform: rotateY(0);
  }
  &.law-item{
    transform: rotateX(0);
  }
  &.work-scheme__item{
    transform: scaleX(1);
  }
  &.advantages-item__image:after{
    transform: scaleX(1);
  }
}