.page404{
	position: relative;
	&__content{
		position: absolute;
		left: 0;
		top: 30px;
		width: 100%;
		z-index: 2;
	}
	.main-slider-wrapper__bg {
	    width: 956px;
	    height: 576px;
	    z-index: 1;
	}
	&__error-num{
		text-transform: uppercase;
		color: $c-white;
		margin-top: rem(50);
		margin-bottom: rem(34);
	}
	&__error-title{
		color: $c-brown;
		margin-bottom: 10px;
	}
	&__error-text{
		color: $c-white;
		width: 302px;
		line-height: 30px;
	}
	&__bottom{
		width: 100%;
		position: absolute;
		bottom: 80px;
		left: 0;
	}
	&__back-link{
		color: $c-white;
		text-decoration: none;
		padding-left: rem(70);
		position: relative;
		&:hover{
			color: $c-brown;
			.more-link__arrow,
			.more-link__square{
				fill: $c-brown;
			}
			.more-link__image-holder:before{
				background-color: $c-brown;
			}
		}
	}
	&__back-link-wrapper{
		&:first-of-type{
			margin-bottom: rem(44);
		}
		.more-link__image-holder{
			opacity: 1;
			left: 0;
			right: initial;
		}
		.more-link__arrow,
		.more-link__square{
			fill: $c-white;
			transition: fill 0.5s ease;
		}
		.more-link__image-holder:before{
			background-color: $c-white;
			transition: background-color 0.5s ease;
			z-index: 1;
		}
	}
}
