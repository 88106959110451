.title{
	font-family: 'RistrettoPro';
	color: inherit;

	&--fz48-fw500{
			font-size: 2.6rem;
		font-weight: 500;
		@include media($def){
			font-size: rem(48);
		}
	}
}
.text{
	font-family: 'RistrettoPro';
	color: inherit;

	&--fz28-fw600{
		font-size: rem(28);
		font-weight: 600;
		// @include media($lg){
		// 	font-size: rem(28);
	// 	}
	}
	&--fz36-fwb{
		font-size: rem(36);
		font-weight: bold;
	}
	&--fz36-fw500{
		font-size: rem(36);
		font-weight: 500;
	}
	&--fz28-fw500{
		font-size: rem(28);
		font-weight: 500;
		// @include media($lg){
		// 	font-size: rem(28);
		// }
	}
	&--fz30-fw500{
		font-size: rem(30);
		font-weight: 500;
	}
	&--fz24-fw500{
		font-size: 24px;
		font-weight: 500;
	}
	&--fz18{
		font-size: 16px;
		@include media($def){
			font-size: 18px;
		}
	}
}
.text2{
	font-family: 'Open Sans';
	color: inherit;

	&--fz32{
		font-size: rem(32);
	}
	&--fz24-fw300{
		font-size: rem(24);
		font-weight: 300;
	}
	&--fz18-fw300{
		font-size: rem(18);
		font-weight: 300;
	}
	&--fz24{
		font-size: rem(24);
	}
	&--fz18{
		font-size: 16px;
		@include media($def){
			font-size: 18px;
		}
	}
	&--fz16{
		font-size: 16px;
	}
	&--fz12{
		font-size: 12px;
	}
	&--fz12-fw300{
		font-size: 12px;
		font-weight: 300;
	}
}