////
/// @group _partials/view-size.scss
////


// scss
// ================================

.view-size {
	position: relative;
	max-width: 1852px;
	padding: 0 16px;
	margin: 0 auto;
	@include clearfix;

	&--md{
		position: relative;
		max-width: 1452px;
		padding: 0 16px;
		margin: 0 auto;
		@include clearfix;
	}
}

// отображение
.view-size {
	&--no-gap {
		max-width: $lg;
	}
}
