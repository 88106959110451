@font-face {
	font-family: 'RistrettoPro';
	src: url('fonts/subset-RistrettoPro-ExtraLight.woff2') format('woff2'),
		url('fonts/subset-RistrettoPro-ExtraLight.woff') format('woff');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'RistrettoPro';
	src: url('fonts/subset-RistrettoPro-Thin.woff2') format('woff2'),
		url('fonts/subset-RistrettoPro-Thin.woff') format('woff');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'RistrettoPro';
	src: url('fonts/subset-RistrettoPro-SemiBold.woff2') format('woff2'),
		url('fonts/subset-RistrettoPro-SemiBold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'RistrettoPro';
	src: url('fonts/subset-RistrettoPro-Bold.woff2') format('woff2'),
		url('fonts/subset-RistrettoPro-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'RistrettoPro';
	src: url('fonts/subset-RistrettoPro-Medium.woff2') format('woff2'),
		url('fonts/subset-RistrettoPro-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'RistrettoPro';
	src: url('fonts/subset-RistrettoPro-Light.woff2') format('woff2'),
		url('fonts/subset-RistrettoPro-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'RistrettoPro';
	src: url('fonts/subset-RistrettoPro-Black.woff2') format('woff2'),
		url('fonts/subset-RistrettoPro-Black.woff') format('woff');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'RistrettoPro';
	src: url('fonts/subset-RistrettoPro-Regular.woff2') format('woff2'),
		url('fonts/subset-RistrettoPro-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans';
	src: url('fonts/subset-OpenSans.woff2') format('woff2'),
		url('fonts/subset-OpenSans.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans';
	src: url('fonts/subset-OpenSansBold.woff2') format('woff2'),
		url('fonts/subset-OpenSansBold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans';
	src: url('fonts/subset-OpenSansLight.woff2') format('woff2'),
		url('fonts/subset-OpenSansLight.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}
