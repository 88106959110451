////
/// @group _partials/view-header.scss
////


// scss
// ================================

.view-header {
	position: fixed;
	top: 0;
	width: 100%;
	left: 0;
	padding: 6px 0;
	z-index: 25;
	background-color: $c-white;
	@include media($def){
		height: initial;
		padding: 16px 0;
	}
}
.view-header-wrapper{
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 46px;
}